import './publicidad.css';
import * as Icon from 'react-bootstrap-icons';
import {Link} from 'react-router-dom'
import Pagos from './pago'
import ModalEnvios from './ModalEnvios'
import ModalPagos from './ModalPagos'
function Publicidad() {

    

    return(
        <div className='container1'>
            <ModalPagos/>
            <ModalEnvios/>
            <a className='pub__card p3' href="https://api.whatsapp.com/send?phone=59895720000&text=Hola!%20Quiero%20hacer%20una%20consulta.">
                <div className='pub__cent'>
                <div className='flex' >
                    <Icon.ChatDots color="#ffbb00" size={38}/>
                    <p className='text'>Conslutanos</p> 
                </div>
                </div>
            </a>
            <a className='pub__card p4' href="https://www.google.com/maps/place/Ceramicas.uy/@-34.8448656,-56.2067856,17z/data=!3m1!4b1!4m5!3m4!1s0x95a1d5333bdb529b:0x70d4d3b82b4c3149!8m2!3d-34.8448656!4d-56.2045969">
            <   div className='pub__cent'>
                    <Icon.GeoAltFill color="#7bbb00" size={38}/>
                    <p className='text'>Donde estamos</p> 
                </div>
                
                
            </a>
        </div>
    );
}

export default Publicidad;