import Pago from './Pagos.jpeg'
// import Pago2 from './metodospago2.png'
import "./pago.css";

function Pagos() {
    return(
        <div className='pagos_header'>
            <div className='pagos_grid'>
                <img className='pagos1' src={Pago} alt=""/>
            </div>
            {/* <div className='pagos_grid'>
                <img className='pagos2' src={Pago2} alt=""/>
            </div> */}
            
        </div>
    );
}

export default Pagos;