import React from "react";
import './cardAdmin.css'
import * as Icon from 'react-bootstrap-icons';
import axios from 'axios'
import NewProduct from '../components/admin/NewProduct'
import EditImages from '../components/admin/EditImages'



function CardAdmin({id,title,price,image,model,box_dim,status}) {

    async function OnClickDelete(){
        const res = await axios.delete('https://api.todoceramicas.com.uy/products/deleteProduct.php?&id=' + id);
        window.location.reload(false);
    }


    return(
        <div className={status==='1' ? 'CardAdmin_container': 'CardAdmin_container CardAdmin_inactive'}>
            <div className='CardAdmin_img'>
                <img src={image} alt=""/>
            </div>
            <div className='CardAdmin_margin CardAdmin_name'>
                Nombre:{title}
            </div>
            <div className='CardAdmin_margin CardAdmin_price'>
                Precio:${price}
            </div>
            <div className='CardAdmin_margin CardAdmin_model'>
                Modelo:{model}
            </div>
            <div className='CardAdmin_margin CardAdmin_dimension'>
                m² por caja:{box_dim}
            </div>
            <button onClick={OnClickDelete} type="button" className='btn btn-danger  CardAdmin_margin'> <Icon.Trash size={20} /></button>
            
            <NewProduct editid={id}/>
            <EditImages editid={id}/>
        </div>
    )
}

export default CardAdmin