import React, { Component } from 'react'
import './category.css';
import axios from 'axios'
import Cards from '../containers/card';
import Cards2 from '../containers/cards2';
import Titulo from '../containers/titulo'
import { TitleSharp } from '@material-ui/icons';

export default class CategoryProducts extends Component {
    
    state={
        notes:[],
        id: '',
        nombre:[],
        matches: window.matchMedia("(max-width: 768px)").matches
    }
    

    async getNotes() {
        const cat = await axios.get('https://api.todoceramicas.com.uy/categories/getCategories.php?&category_id='+this.props.match.params.id)
        const res = await axios.get('https://api.todoceramicas.com.uy/products/getProducts.php?&category='+this.props.match.params.id)
        document.title = cat.data[0].name
        console.log(cat)
        this.setState({
            notes: res.data,
            id: this.props.match.params.id,
            nombre: cat.data,
            matches: window.matchMedia("(max-width: 768px)").matches
        })
    }
    
    componentDidMount() {
        this.getNotes();
    }
    
    render(){
        if(this.state.id != this.props.match.params.id){
            this.componentDidMount();
        }
        console.log(this.state.matches)
        var image_array;
        const tituloArray = this.state.nombre[0]
        let titulo = ''
        if(tituloArray !== undefined)
        titulo = tituloArray.name
        if(this.state.matches){
        return(
            <div className='category_max'>
                <div className='category_title'>
                    <Titulo titulo={titulo}/>
                </div>
                <div className='category_container'>
                    
                {
                this.state.notes.map(note => (image_array = note.images.split(","), (  
                    <Cards           
                        id={note.product_id}
                        title={note.name}
                        price={note.price}
                        oldprice={note.oldprice}
                        image={JSON.parse(note.images)[0]}
                        image2={JSON.parse(note.images)[1]}
                        model={note.model}
                        box_dim={note.box_dimension}
                    />
                ))) 
                }
                </div>
                </div>
        )}else{
            return(
                <div className='category_max'>
                    <div className='category_title'>
                        <Titulo titulo={titulo} />
                    </div>
                    <div className='category_container'>

                        {
                            this.state.notes.map(note => (image_array = note.images.split(","), (
                                <Cards2 value={note} />
                            )))
                        }
                    </div>
                </div>
            )
        }
    }


}