import Modal from 'react-bootstrap/Modal';
import * as Icon from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import React, {useState} from 'react';
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxOpen, faRuler } from '@fortawesome/free-solid-svg-icons'
import './card.css'
import './Modal.css'
import ButtonCookie from './ButtonCookie'

function ModalButton({idAgregar,model,box_dim,name,image,price_m}) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


  const [cantEnMetros, setCant] = useState();
  var cajas=0;
  if(cantEnMetros){
  cajas= Math.trunc(cantEnMetros/box_dim)+1;
  let Real = cantEnMetros/box_dim
  //let cajasReal= Math.trunc(this.state.cantMetros/this.state.box_dimension)+1;
  let coma = Real - cajas
  if(coma === -1){
    cajas = cajas -1
  }
  }
  var cantidadReal = (cajas * box_dim).toFixed(2);
  let button_icon;
  if(window.matchMedia("(min-width: 768px)").matches) {
    button_icon = <Icon.CartDash color="black" size={30}/>;
  } else {
    button_icon = 'Agregar a Presupuesto';
  }

    return (
      <>
        <Button variant="warning" onClick={handleShow} className='cards__cont icon1'>
            {button_icon}
        </Button>
  
        <Modal className='modal_max' show={show} onHide={handleClose} animation={false}>
          <Modal.Header className='modal_header' closeButton>
            <Modal.Title className='modal_title' >USTED HA AGREGADO CORRECTAMENTE A SU PRESUPUESTO:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                <Form> 
                        <Form.Control type="number" value={cantEnMetros} onChange={e => setCant(e.target.value)}  placeholder='Cantidad en m²' />
                </Form>
          </Modal.Body>
          <Modal.Body>
            <div><FontAwesomeIcon icon={faBoxOpen} /> Cajas : {cajas}</div>
            <div><FontAwesomeIcon icon={faRuler} /> Cantidad real en metros: {cantidadReal}</div>
          </Modal.Body>
          <Modal.Footer className='modal_footer'>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
            <ButtonCookie idAdd={idAgregar} cajas={cajas} cantEnM={cantidadReal} model={model} name={name} image={image} precio={price_m}/>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
export default ModalButton;