import "./BannerPublicitario.css";

function BannerPublicitario({imagen}) {
    if(imagen){
        return(
            <div className='bennerpub_header'>
                <img className='bennerpub_img' src={imagen} alt=""/>
            </div>
        );
    }else{
        return <div></div>
    }

}

export default BannerPublicitario;