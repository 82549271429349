import './home.css'
import Publicidad from '../containers/publicidad'
import CarouselCards from '../containers/carousercards'
import Titulo from '../containers/titulo'
import BannerPublicitario from '../containers/BannerPublicitario'
import FotoBanner from './Banner.png'
import React, { Component } from 'react';
import axios from 'axios'
import Cards2 from '../containers/cards2'
import Cards from '../containers/card';
import Spinner from 'react-bootstrap/Spinner'

export default class Home extends Component {

    state = {
        products: [],
        banners: [],
        loading: true
    }

    async componentDidMount() {
        document.title = 'CeramicasUy' 
        this.setState({
            loading: true
        })
        window.scrollTo(0, 0)
        const res = await axios.get('https://api.todoceramicas.com.uy/products/getProducts.php')
        const ban = await axios.get('https://api.todoceramicas.com.uy/banners/getBanners.php')
        var items = res.data
        await items.sort(function (a, b) { 
            if (a.sort > b.sort) {
              return 1;
            }
            if (a.sort < b.sort) {
              return -1;
            }
            return 0;
        });
        this.setState({
            products: items,
            banners: ban.data
        })
        this.setState({
            loading: false
        })
    }

    render() {
        const Products1 = this.state.products.slice(0, 8);
        const Products2 = this.state.products.slice(9, 17);
        const Products3 = this.state.products.slice(17, 25);
        const Products4 = this.state.products.slice(25, 33);
        const Products5 = this.state.products.slice(33, 41);
        if(this.state.loading){
            return(
                <div className='home_loading'>
                    <div className='home_loading_in'>
                        <Spinner animation="border" />
                    </div>
                </div>
            )
        }else{
        if (window.matchMedia("(min-width: 768px)").matches) {
            return (
                <div className='home'>
                    <Publicidad />
                    <BannerPublicitario imagen={this.state.banners[0].url} />
                    <div className='home_conteiner_grid'>
                        {Products1.map(product => (
                            <div className='prueba_cad2_1'>
                                <Cards2 value={product} />
                            </div>
                            
                        ))}
                    </div>
                    <BannerPublicitario imagen={this.state.banners[1].url} />
                    <div className='home_conteiner_grid'>
                        {Products2.map(product => (
                            <Cards2 value={product} />
                        ))}
                    </div>
                    <BannerPublicitario imagen={this.state.banners[2].url} />
                    <div className='home_conteiner_grid'>
                        {Products3.map(product => (
                            <Cards2 value={product} />
                        ))}
                    </div>
                    <BannerPublicitario imagen={this.state.banners[3].url} />
                    <div className='home_conteiner_grid'>
                        {Products4.map(product => (
                            <Cards2 value={product} />
                        ))}
                    </div>
                    <BannerPublicitario imagen={this.state.banners[4].url} />
                    <div className='home_conteiner_grid'>
                        {Products5.map(product => (
                            <Cards2 value={product} />
                        ))}
                    </div>
                </div>
            )
        } else {
            return (
                <div className='home'>
                    <BannerPublicitario imagen={this.state.banners[0].url} />
                    <div className='home_conteiner_grid'>
                        {Products1.map(product => (
                            <div className='prueba_cad2_1'>
                                <Cards
                                    id={product.product_id}
                                    title={product.name}
                                    price={product.price}
                                    oldprice={product.oldprice}
                                    image={JSON.parse(product.images)[0]}
                                    image2={JSON.parse(product.images)[1]}
                                    model={product.model}
                                    box_dim={product.box_dimension}
                                />
                            </div>
                            
                        ))}
                    </div>
                    <BannerPublicitario imagen={this.state.banners[1].url} />
                    <div className='home_conteiner_grid'>
                        {Products2.map(product => (
                            <div className='prueba_cad2_1'>
                                <Cards
                                    id={product.product_id}
                                    title={product.name}
                                    price={product.price}
                                    oldprice={product.oldprice}
                                    image={JSON.parse(product.images)[0]}
                                    image2={JSON.parse(product.images)[1]}
                                    model={product.model}
                                    box_dim={product.box_dimension}
                                />
                            </div>
                            
                        ))}
                    </div>
                    <BannerPublicitario imagen={this.state.banners[2].url} />
                    <div className='home_conteiner_grid'>
                        {Products3.map(product => (
                            <div className='prueba_cad2_1'>
                                <Cards
                                    id={product.product_id}
                                    title={product.name}
                                    price={product.price}
                                    oldprice={product.oldprice}
                                    image={JSON.parse(product.images)[0]}
                                    image2={JSON.parse(product.images)[1]}
                                    model={product.model}
                                    box_dim={product.box_dimension}
                                />
                            </div>
                            
                        ))}
                    </div>
                    <BannerPublicitario imagen={this.state.banners[3].url} />
                    <div className='home_conteiner_grid'>
                        {Products4.map(product => (
                            <div className='prueba_cad2_1'>
                                <Cards
                                    id={product.product_id}
                                    title={product.name}
                                    price={product.price}
                                    oldprice={product.oldprice}
                                    image={JSON.parse(product.images)[0]}
                                    image2={JSON.parse(product.images)[1]}
                                    model={product.model}
                                    box_dim={product.box_dimension}
                                />
                            </div>
                            
                        ))}
                    </div>
                    <BannerPublicitario imagen={this.state.banners[4].url} />
                    <div className='home_conteiner_grid'>
                        {Products5.map(product => (
                            <div className='prueba_cad2_1'>
                                <Cards
                                    id={product.product_id}
                                    title={product.name}
                                    price={product.price}
                                    oldprice={product.oldprice}
                                    image={JSON.parse(product.images)[0]}
                                    image2={JSON.parse(product.images)[1]}
                                    model={product.model}
                                    box_dim={product.box_dimension}
                                />
                            </div>
                            
                        ))}
                    </div>
                    <Publicidad />
                </div>
            )
        }
    }
    }
}