import React, { useState } from 'react';
import "./Header.css"
import "./Ambiente.css"
import foto from './logo.png'
import { Link, Redirect } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CallIcon from '@material-ui/icons/Call';
import RoomIcon from '@material-ui/icons/Room';
import * as Icon from 'react-bootstrap-icons';
import Cookies from 'universal-cookie';
import Ambiente from './Ambiente'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import SearchProp from '../containers/Search'


function Header() {
  const [search, setSearch] = useState(false)
  const [navbar, setNavBar] = useState(true)
  const [first, setFirst] = useState(false)
  const [second, setSecond] = useState(0)
  var buscador
  const cookies = new Cookies();
  var cantidad1 = cookies.get('cantidad');
  var subtot = cookies.get('subtotal');
  if (!cantidad1) {
    cantidad1 = 0;
    subtot = 0;
  }
  subtot = parseFloat(subtot).toFixed(2);
  
  const changeBar = () => {
    if (window.scrollY >= 100) {
      setNavBar(false);
    } else {
      setNavBar(true)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log(buscador)
    } else {
      buscador = e.target.value;
    }
  }


  const changeSearch = () => {
    if (search) {
      setSearch(false);
    } else {
      setSearch(true)
    }
  }

  const onClickFirst = e => {
    setFirst(!first)
  }

  const onClickSecond = e => {
    if (second === e)
      setSecond(0)
    else
      setSecond(e)
  }


  let incon_search;
  if (!search) {
    incon_search = <Icon.Search className='header_icon_search_fixed' size={15} color={search ? 'rgb(60, 59, 137)' : 'white'} />;
  } else {
    incon_search = <Icon.X className='header_icon_search_fixed' size={15} color={'rgb(60, 59, 137)'} />
  }


  window.addEventListener('scroll', changeBar);

  if (window.matchMedia("(min-width: 768px)").matches) {
    if (navbar) {
      return (
        <div className='nav_bar_max'>
          <nav className='header__maximo'>
            <div className='up__line_max'>
              <div className='up__line'>
                <div className='upp__text'>
                  <a className='upp_color_text' href="https://api.whatsapp.com/send?phone=59895720000&text=Hola!%20Quiero%20hacer%20una%20consulta.">
                    <WhatsAppIcon />
                    <h10>+598-95-720-000</h10>
                  </a>
                </div>
                <div className='upp__text2'>
                  <a className='upp_color_text' href="tel:2304-88-05">
                    <CallIcon />
                    <h10>2304-88-05</h10>
                  </a>
                </div>
                <div className='upp__text3'>
                  <a className='upp_color_text' href="https://www.google.com/maps/place/Ceramicas.uy/@-34.8448656,-56.2067856,17z/data=!3m1!4b1!4m5!3m4!1s0x95a1d5333bdb529b:0x70d4d3b82b4c3149!8m2!3d-34.8448656!4d-56.2045969">
                    <RoomIcon />
                    <h10>Av. Millán 4373</h10>
                  </a>
                </div>

              </div>
            </div>
            <div className='header'>
              <div>
                <Link to='/'>
                  <img className="header__logo" src={foto} alt="logo"></img>
                </Link>
              </div>
              <div className="upp__text4">
                <SearchProp />
                <div className="header__nav">
                  <Link to="/checkout" className="header__link">
                    <div className="header_optionBasket">
                      <Icon.Cart3 size={50} />
                      <spam className='header__optionLineTwo header__basketCount'>{cantidad1}</spam>
                      <div className='header__presupuesto'>
                        <p className='presupuesto__text'>MI PRESUPUESTO</p>
                        <p className='presupuesto__price'>${subtot}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
      )
    } else {
      return (
        <div className='header_fixed'>
          <div className='header'>
            <div>
              <Link to='/'>
                <img className="header__logo" src={foto} alt="logo"></img>
              </Link>
            </div>
            <div className="upp__text4">
              <SearchProp />
              <div className="header__nav">
                <Link to="/checkout" className="header__link">
                  <div className="header_optionBasket">
                    <Icon.Cart3 size={50} />
                    <spam className='header__optionLineTwo header__basketCount'>{cantidad1}</spam>
                    <div className='header__presupuesto'>
                      <p className='presupuesto__text'>MI PRESUPUESTO</p>
                      <p className='presupuesto__price'>${subtot}</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
  else {
    if (navbar) {
      return (
        <div className='fixed_mob'>
          <nav className='header__maximo'>
            <div className='up__line_max'>
              <div className='up__line'>
                <div className='upp__text'>
                  <a className='upp_color_text' href="https://api.whatsapp.com/send?phone=59895720000&text=Hola!%20Quiero%20hacer%20una%20consulta.">
                    <WhatsAppIcon />
                    <h8>+598-95-720-000</h8>
                  </a>
                </div>
                <div className='upp__text2'>
                  <a className='upp_color_text' href="tel:2304-88-05">
                    <CallIcon />
                    <h8>2304-88-05</h8>
                  </a>
                </div>
                <div className='upp__text3'>
                  <a className='upp_color_text' href="https://www.google.com/maps/place/Ceramicas.uy/@-34.8448656,-56.2067856,17z/data=!3m1!4b1!4m5!3m4!1s0x95a1d5333bdb529b:0x70d4d3b82b4c3149!8m2!3d-34.8448656!4d-56.2045969">
                    <RoomIcon />
                    <h8>Av. Millán 4373</h8>
                  </a>
                </div>

              </div>
            </div>
            <div className='header'>
              <div>
                <Link to='/'>
                  <img className="header__logo" src={foto} alt="logo"></img>
                </Link>
              </div>

              <div className="upp__text4">
                <div className={search ? 'search_mobil_add' : 'search_mobil'}>
                  <button className='search_button_mob' onClick={changeSearch}>
                    {incon_search}
                  </button>
                </div>
                <SearchProp search={search} />
                <div className="header__nav">
                  <Link to="/checkout" className="header__link">
                    <div className={search ? "header_optionBasket" : "header_optionBasket_off"}>
                      <div className={search ? 'search_mobil_off' : 'text_presupuesto_mob'}>Tu Presupuesto</div>
                      <Icon.Cart3 size={40} />
                      <spam className='header__optionLineTwo header__basketCount'>{cantidad1}</spam>

                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
      );
    }
    else {
      return (
        <div>
          <div className='header_fixed'>
            <div className='header_ambiente_mob'>
              <div className='acordion_mob'>
                <Icon.List color={'white'} size={30} onClick={onClickFirst} />
              </div>
              <div className='position_cart_mob_fixed'>
                <Link to="/checkout" className="header__link">
                  <div className={"header_optionBasket"}>
                    <div className={'text_presupuesto_mob'}>Tu Presupuesto</div>
                    <Icon.Cart3 size={40} />
                    <spam className='header__optionLineTwo header__basketCount'>{cantidad1}</spam>
                  </div>
                </Link>

              </div>

            </div>
            <div className='header_hika'>
              {(() => {
                if (first) {
                  return (
                    <div className='ambiente_deploy'>
                      <div >
                        <div className='ambiente_deploy_cont ambiente_deploy1'>
                          <Link className='ambiente_deploy_link' to="/category/9">PISOS</Link><div onClick={() => onClickSecond(1)} className='ambiente_deploy_arrow'><Icon.CaretDown size={30} /></div>
                        </div>
                        {(() => {
                          if (second === 1) {
                            return (
                              <div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">INTERIORES</Link>
                                </div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">EXTERIORES</Link>
                                </div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">SIMIL MADERA</Link>
                                </div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">GRES</Link>
                                </div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">PORCELANATO</Link>
                                </div>
                              </div>

                            )
                          }
                        })()}
                        <div className='ambiente_deploy_cont'>
                          <Link className='ambiente_deploy_link' to="/category/11">PAREDES</Link><div onClick={() => onClickSecond(2)} className='ambiente_deploy_arrow'><Icon.CaretDown size={30} /></div>
                        </div>
                        {(() => {
                          if (second === 2) {
                            return (
                              <div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">BRILLANTE</Link>
                                </div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">MATE</Link>
                                </div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">SIMIL PIEDRA</Link>
                                </div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">DECORADOS</Link>
                                </div>
                              </div>
                            )
                          }
                        })()}
                        <div className='ambiente_deploy_cont'>
                          <Link className='ambiente_deploy_link' to="/category/12">LOZA SANITARIA</Link><div onClick={() => onClickSecond(3)} className='ambiente_deploy_arrow'><Icon.CaretDown size={30} /></div>
                        </div>
                        {(() => {
                          if (second === 3) {
                            return (
                              <div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">INODOROS</Link>
                                </div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">PILETA Y PEDESTAL</Link>
                                </div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">BIDET</Link>
                                </div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">MUEBLE CON BACHA</Link>
                                </div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">TAPAS DE INODORO</Link>
                                </div>
                              </div>
                            )
                          }
                        })()}
                        <div className='ambiente_deploy_cont'>
                          <Link className='ambiente_deploy_link' to="/category/13">ACCESORIOS</Link><div onClick={() => onClickSecond(4)} className='ambiente_deploy_arrow'><Icon.CaretDown size={30} /></div>
                        </div>
                        {(() => {
                          if (second === 4) {
                            return (
                              <div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">GRIFERIA</Link>
                                </div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">ADHESIVOS</Link>
                                </div>
                                <div className='ambiente_deploy_cont ambiente_deploy1'>
                                  <Link className='ambiente_deploy_link' to="/category/9">PASTINAS</Link>
                                </div>
                              </div>
                            )
                          }
                        })()}
                        <div className='ambiente_deploy_cont'>
                          <Link className='ambiente_deploy_link' to="/category/14">PROMOCIONES</Link><div className='ambiente_deploy_arrow'><Icon.CaretDown color={'white'} size={30} /></div>
                        </div>
                      </div>
                    </div>)
                }
              })()}
            </div>
          </div>

          <div className='margin_top_fixed_mob'>

          </div>
        </div>
      )
    }
  }
};

export default Header
