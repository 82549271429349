import React, {Component} from 'react';
import './pruebacars.css'
import Cards from './cards2'
import axios from 'axios'

export default class PruebaCards extends Component{

    state={
        products:[],
        loading: false
    }

    async componentDidMount(){
        this.setState({
            loading: true
        })
        const res = await axios.get('https://api.todoceramicas.com.uy/products/getProducts.php?&category='+1)
        this.setState({
            products: res.data
        })
        this.setState({
            loading: false
        })
    }

    render(){
        if(this.state.loading){
            return(
                <div>
                    Loading..
                </div>
            )
        }else
        return(
            <div className='prueba_cat_container'>
                {this.state.products.map(value => (
                    <Cards value={value}/>
                ))}
            </div>
        )
    }
}