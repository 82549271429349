import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from 'universal-cookie';

function ButtonCookie({idAdd,cantEnM,model,name,image,precio,cajas}) {
  



  const handleSubmit = e => {
    e.preventDefault();
      const cookies = new Cookies();
      var cantidad1 = cookies.get('cantidad');
      var subtotal=0;
      var model_carry=[];
      var id_carry=[];
      var cant_carry=[];
      var name_carry=[];
      var imagen_carry=[];
      var precio_carry=[];
      var cajas_carry=[]
      var b = Number(cantidad1);
      if(b > 0){
          b=b+1;
          subtotal= cookies.get('subtotal');
          subtotal= Number(subtotal);
          subtotal= subtotal + precio * cantEnM;
          id_carry = cookies.get('id');
          cant_carry = cookies.get('cantidad_carry');
          model_carry= cookies.get('model');
          name_carry= cookies.get('name');
          imagen_carry= cookies.get('image');
          precio_carry= cookies.get('precio');
          cajas_carry= cookies.get('cajas')
      }else{
          b=1;
          subtotal=precio * cantEnM;
      }
      imagen_carry.push(image);
      model_carry.push(model);
      id_carry.push(idAdd);
      cant_carry.push(cantEnM);
      name_carry.push(name);
      precio_carry.push(precio);
      cajas_carry.push(cajas);
      cookies.set('id', id_carry, {path: '/'});
      cookies.set('cantidad_carry', cant_carry, {path: '/'});
      cookies.set('subtotal', subtotal, {path: '/'});
      cookies.set('cantidad', b, {path: '/'});
      cookies.set('model', model_carry, {path: '/'});
      cookies.set('name', name_carry, {path: '/'});
      cookies.set('image', imagen_carry, {path: '/'});
      cookies.set('precio', precio_carry, {path: '/'});
      cookies.set('cajas', cajas_carry, {path: '/'})
      window.location.href='';
  };



    return (
        <div className="loginContainer">
          <form onSubmit={handleSubmit}>
            <input
              type="submit"
              className="btn btn-primary btnLogin"
              value="Agregar presupuesto"
            />
          </form>
        </div>
    );

}

export default ButtonCookie;