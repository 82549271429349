import './CarouselAdmin.css';
import React, {Component} from 'react';
import carousel1 from '../carousel1.jfif'
import carousel2 from '../carousel2.jfif'
import carousel3 from '../carousel3.jfif'
import carousel4 from '../carousel4.jfif'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'

let buttoSave = 'Guardar'
export default class CarouselAdmin extends Component{

    state= {
        imagenes: [],
        timer: '',
        carouseles: [],
        link: '',
        cargando: false,
        errortimer: false,
        errorimg: false
      }

    async componentDidMount(){
        const cat = await axios.get('https://api.todoceramicas.com.uy/carousel/getCarousel.php')
        this.setState({
            carouseles: cat.data,
            cargando: false,
            errortimer:false,
            errorimg: false
        })
    }

    onChange = (e) => {
        this.setState({
          imagenes: e.target.files
      })
    }

    onInputChange = (e) => {
        this.setState({
            timer: e.target.value
        })
    }

    onInputChangeLink = (e) => {
        this.setState({
            link: e.target.value
        })
    }

    onClickButtonDelete = async (e) =>{
        const res = await axios.delete('https://api.todoceramicas.com.uy/carousel/deleteCarousel.php?&id=' + e.target.value);
        console.log(res.data)
        e.preventDefault()
        this.componentDidMount()
    }

    onClickButton = async (e) =>{
        e.preventDefault();
        if(this.state.timer>0){
            buttoSave = <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            this.setState({
                errortimer: false,
                errorimg: false
            })
            const fromData = new FormData();
            fromData.append( 
            "image", 
            this.state.imagenes[0], 
            this.state.imagenes[0].name 
            )
            const res = await axios.post('https://api.todoceramicas.com.uy/images/carousel/addImage.php', fromData);
            let posicion = res.data.indexOf('Sorry');
            if (posicion !== -1){
                buttoSave = 'Guardar'
                this.setState({
                    errorimg: true
                })    
            }
            else{
                const jsonimg = await JSON.stringify(res.data);
                const newNote ={
                    image: jsonimg,
                    time: this.state.timer,
                    link: this.state.link
                }
                const json = await JSON.stringify(newNote);
                const resjson = await axios.post('https://api.todoceramicas.com.uy/carousel/addCarousel.php',json)
                buttoSave = 'Guardar'
                this.componentDidMount()
            }
        }else{
            this.setState({
                errortimer: true
            })
        }
        buttoSave = 'Guardar'
    }


    render(){

        let image
        return(
            <div className='CarouselAdmin'>
                <div className='CarouselAdmin_container' >
                    <div className='CarouselAdmin_timer'>
                        Link: 
                        <input type="text"
                        onChange={this.onInputChangeLink}
                        value={this.state.link}
                        />
                    </div>
                    <div className='CarouselAdmin_timer'>
                        Tiempo: 
                        <input type="number"
                        onChange={this.onInputChange}
                        value={this.state.timer}
                        />
                        ms
                        {(() => {
                            if (this.state.errortimer) {
                            return (
                                <Alert variant='danger'>
                                    Campo Tiempo es obligatorio
                                </Alert>
                            )
                            }
                        })()}
                    </div>
                    <div className='CarouselAdmin_timer'>
                        <Form.Group>
                          <Form.Control type='file' name='images' multiple onChange={this.onChange}/>
                        </Form.Group>
                        {(() => {
                            if (this.state.errorimg) {
                            return (
                                <Alert variant='danger'>
                                    Error al subir la imagen
                                </Alert>
                            )
                            }
                        })()}
                    </div>
                    <div className='CarouselAdmin_timer'>
                    <button type="button" className="btn btn-success"  onClick={this.onClickButton}>{buttoSave}</button>
                    </div>
                </div>
                {
                this.state.carouseles.map(note =>(
                <div className='CarouselAdmin_container' >
                    {(() => {image=note.image.split('"')})()}
                    <img className='CarouselAdmin_img' src={image[1]} alt=""/>
                    <div className='CarouselAdmin_timer'>
                        Tiempo: {note.time}
                    </div>
                    <div className='CarouselAdmin_timer CarouselAdmin_timer_width'>
                        Link: {note.link}
                    </div>
                    <div className='CarouselAdmin_timer'>
                        <button type="button" className="btn btn-danger" value={note.id} onClick={this.onClickButtonDelete}>Eliminar</button>
                    </div>
                </div>
                ))}
                
            </div>
        )
    }

}