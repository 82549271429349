import './publicidad.css';
import Modal from 'react-bootstrap/Modal';
import * as Icon from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import React, {useState} from 'react';
import envios from "./envios.jpg"


function ModalButtonEnvios() {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className='pub__card p2 publicidad_cursor' onClick={handleShow}>
                <div className='pub__cent'>
                    <Icon.Truck color="#ea4335" size={38}/>
                    <p className='text'>Envios</p> 
                </div>
            </div>
    
          <Modal className='modal_envios' show={show} onHide={handleClose} animation={false}>
            <Modal.Header className='modal_header' closeButton>
              <Modal.Title className='modal_title' >ENVIOS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='modalpading'>
                <img src={envios} alt="" width="100%"/>
              </div>
            </Modal.Body>
            <Modal.Footer className='modal_footer'>
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
    
  export default ModalButtonEnvios;