import './Ceramicas.css'
import React, {Component} from 'react';
import axios from 'axios'
import CardAdmin from '../../containers/cardAdmin';
import NewProduct from './NewProduct'
import Form from 'react-bootstrap/Form'
import Posts from '../../containers/Posts'
import Pagination from '../../containers/pagination';
import Cookies from 'universal-cookie';

export default class Ceramicas extends Component{


    state= {
        users:[],
        _id: '',
        name:'',
        product_id: '',
        model: '',
        price: '',
        width: '',
        box_dimension: '',
        length: '',
        images: '',
        busqueda: '',
        filtro: '',
        refresh: false,
        check: [],
        posts: [],
        loading: false,
        currentPage: 1,
        postsPerPage: 10
    }


    async componentDidMount(){
        window.scrollTo(0, 0)
        this.setState({ loading: true });
        const querystring = window.location.search
        const params = new URLSearchParams(querystring)
        const busqueda = params.get('busqueda')
            if(busqueda){
                const res = await axios.get('https://api.todoceramicas.com.uy/products/getProduct.php?&name='+busqueda)
                this.setState({
                    users: res.data,
                    posts: res.data
                })
            }else{
                const res = await axios.get('https://api.todoceramicas.com.uy/products/getProducts.php?&all=1')
                this.setState({
                    users: res.data,
                    posts: res.data
                })
            }

            this.state.users.map(note =>(
               this.setState({
                   name: note.name,
                   product_id: note.product_id,
                   model: note.model,
                   price: note.price,
                   width: note.width,
                   box_dimension: note.box_dimension,
                   length: note.length,
                   images: note.images
               })
            ))
            this.setState({ loading: false });
    }

    async refresh(){
        const res = await axios.get('https://api.todoceramicas.com.uy/products/getProducts.php')
            this.setState({
                users: res.data,
                posts: res.data
            })
    }

    async getNotes(e) {
        const res = await axios.get('https://api.todoceramicas.com.uy/products/getProducts.php?&category='+e)
        this.setState({
            users: res.data,
            posts: res.data
        })
    }

    async getName(e) {
        //console.log('http://192.168.1.191:4000/api/products/name/'+e)
        const res = await axios.get('https://api.todoceramicas.com.uy/products/getProduct.php?&name='+e)
        //console.log(res)
        this.setState({
            users: res.data,
            posts: res.data
        })
    }

    onInputChangeFilter = e =>{
        this.setState({
            [e.target.name]: e.target.value
        })
        if(e.target.value == 'Todos'){
            this.componentDidMount();
        }else{
            this.getNotes(e.target.value)
        }
    }

    onInputChange = e =>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onClickButton = async (e) =>{
        e.preventDefault();
        this.getName(this.state.busqueda);
    }

    onClickDelete = async (e) =>{
        e.preventDefault();
        const cookies = new Cookies();
        const newCat = cookies.get('admincheckdelete');
        await this.state.check.map(index =>(
          axios.delete('https://api.todoceramicas.com.uy/products/deleteProduct.php?&id=' + index)
        )) 
        cookies.remove('admincheckdelete', {path: '/'});
        window.location.reload(false);
    }

    onCheckChange = e =>{
        const newCat = this.state.check;
        
        if(!newCat.includes(e.target.value)){
          newCat.push(e.target.value);
          
        }else{
          var i= newCat.indexOf(e.target.value);
          newCat.splice(i,1);
        }
        this.setState({
          check: newCat
        })
    }



    render(){
        const { currentPage, postsPerPage, posts, loading } = this.state;

        const indexOfLastPost = currentPage * postsPerPage;
        const indexOfFirstPost = indexOfLastPost - postsPerPage;
        const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
    
        const paginate = pageNum => this.setState({ currentPage: pageNum });
    
        const nextPage = () => {
            this.setState({ currentPage: currentPage + 1 });
            const cookies = new Cookies();
            cookies.remove('admincheckdelete', {path: '/'});
        }
    
        const prevPage = () => {
            this.setState({ currentPage: currentPage - 1 });
            const cookies = new Cookies();
            cookies.remove('admincheckdelete', {path: '/'});
        }

        return(
            <div className='Ceramicas_container'>
                <div className='Ceramicas_nabbar'>
                    <div className='Ceramicas_nabbar_buttons'>
                        <NewProduct />
                    </div>

                </div>
                <div>
                <div className='ceramicas_filtro'>
                    <div className='ceramicas_filtro_div'>
                        <Form>
                        <Form.Label>Filtrar por Categoria</Form.Label>
                            <Form.Control
                            as="select"
                            placeholder="filtro"
                              name="filtro"
                              onChange={this.onInputChangeFilter}
                              value={this.state.nombre}
                              required>
                                <option>Todos</option>
                                <option value='1'>Baño</option>
                                <option value='2'>Cocina</option>
                                <option value='3'>Pared</option>
                                <option value='4'>Exteriores</option>
                                <option value='5'>Decorados</option>
                            </Form.Control>
                        </Form>
                    </div>
                    <div className='ceramicas_filtro_div2'>
                        <Form>
                        <Form.Label>Buscar</Form.Label>
                        <Form.Control
                              type="text"
                              placeholder="Buscar"
                              name="busqueda"
                              onChange={this.onInputChange}
                              value={this.state.nombre}
                              required/>
                        </Form>
                        <div className='ceramicas_filtro_button'>
                            <button type="button" className="btn btn-primary" onClick={this.onClickButton}>Buscar</button>
                        </div>

                    </div>
                    <div className='ceramicas_button'>
                        <button type="button" className="btn btn-danger" onClick={this.onClickDelete}>Eliminar</button>
                    </div>

                </div>
                {/*
                this.state.users.map(note => (image_array = note.images.split(" , "), (
                <div key={note.product_id} > 
                    <div className='Ceramicas_margin'>
                    <input className="form-check-input ceramicas_check"
                     type="checkbox"
                      id="inlineCheckbox1"
                       value={note.product_id}
                       onChange={this.onCheckChange}
                       ></input>
                        <CardAdmin
                            id={note.product_id}
                            title={note.name}
                            price={note.price}
                            image={JSON.parse(note.images)[0]}
                            model={note.model}
                            box_dim={note.box_dimension}
                        />
                    </div>
                </div>
                )))
                */}
                    <div>
                        <Posts posts={currentPosts} loading={loading} />
                        <Pagination postsPerPage={postsPerPage} totalPosts={posts.length} paginate={paginate} nextPage={nextPage} prevPage={prevPage} />
                    </div>
                </div>
            </div>
        )
    }

}
