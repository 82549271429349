import './galery.css'

import React from 'react';
import ImageGallery from 'react-image-gallery';
import { Height } from '@material-ui/icons';
 

class Galery extends React.Component {
  render() {
    if(window.matchMedia("(min-width: 768px)").matches){ 
    return (

            <ImageGallery 
              items={this.props.images_in}
              showFullscreenButton={false}
              showPlayButton={false}
              showNav={false}
              thumbnailPosition={'left'}
            />
            )
  }else{

    return (
          <div className='galery_container'>
            <ImageGallery 
              items={this.props.images_in}
              showFullscreenButton={false}
              showPlayButton={false}
              showNav={false}
            />
          </div>);
  }
  }
}

export default Galery