//import "./Carousel.css"
import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Banner.css'
import carousel1 from './carousel1.jfif'
import carousel2 from './carousel2.jfif'
import carousel3 from './carousel3.jfif'
import carousel4 from './carousel4.jfif'
import React, {Component} from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'

export default class Banner extends Component{
  
  state= {
    carouseles: [],

  }

async componentDidMount(){
    const cat = await axios.get('https://api.todoceramicas.com.uy/carousel/getCarousel.php')
    this.setState({
        carouseles: cat.data
    })
}
  
  
  render(){
  const intervalo = '5000'
  let image
    return(
      <div className='banner_margin'>
        <Carousel >
              {
                this.state.carouseles.map(note =>(
                    <Carousel.Item interval={note.timer}>
                      {(() => {image=note.image.split('"')})()}
                    <Link to={note.link}>
                    <img
                      className="d-block mx-auto img-fluid w-100 banner_width"
                      src={image[1]}
                      alt="First slide"
                      
                    />
                    </Link>

                    </Carousel.Item>

              ))}
        </Carousel>
      </div>
    );
  }
}
