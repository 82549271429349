import './Presupuestos.css'
import React, {Component} from 'react';
import Form from 'react-bootstrap/Form'
import axios from 'axios'
import CardAdmin from '../../containers/cardAdmin';
import NewProduct from './NewProduct'

export default class AdminPresupuesto extends Component{

    state={
        products: [],
        _id: '',
        busqueda: '',
        product: [],
        products_id: [],
        _id_p: ''
    }

    async componentDidMount(){
        const res = await axios.get('https://api.todoceramicas.com.uy/orders/getOrders.php');
        this.setState({
            products: res.data
        })
    }

    async funcionPrueba(e) {
        await this.setState({
            _id: e,
            product: this.state.products[e]
        })
        await this.setState({
            products_id: this.state.product.products
        })
        await this.setState({
            _id_p: this.state.products_id.id
        })
    }

    onInputChange = e =>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onClickButton = async (e) =>{
        e.preventDefault();
        this.setState({
            _id: this.state.busqueda
        })
    }


    render(){

        const prueba=['1','2','3'];
        var as= []
        return(
            <div className='AdminPresupuesto'>
                <div className='AdminPresupuesto_busqueda'>
                            <div className='AdminPresupuesto_busqueda_title'>
                                <Form>
                                <Form.Label>Buscar</Form.Label>
                                <Form.Control 
                                    type="number" 
                                    placeholder="Buscar"
                                    name="busqueda"
                                    onChange={this.onInputChange}
                                    value={this.state.name}
                                    required/>   
                                </Form>
                            </div>
                            <div className='AdminPresupuesto_busqueda_button'>
                                <button type="button" className="btn btn-primary" onClick={this.onClickButton}>Buscar</button>
                            </div>
                        </div>
                <div>
                {
                            
                            this.state.products.map(note => (
                                
                                <div className='AdminPresupuesto_card' key={note.order_id}>
                                    <div className='AdminPresupuesto_card_title'>
                                    Numero de presupuesto: {note.order_id}
                                    </div>
                                    <div className='AdminPresupuesto_cont'>
                                        <div className='AdminPresupuesto_card_title'>
                                            Productos:
                                        </div>
                                    {   
                                        JSON.parse(note.products).map(prod => (
                                            <div className='AdminPresupuesto_prod'>
                                                id: {prod.id} cajas: {prod.cajas} precio: {prod.precio} cantiada:{prod.cantidad}
                                            </div>

                                        ))
                                    }
                                    </div>
                                    <div className='AdminPresupuesto_card_buttons'>
                                    <div className='AdminPresupuesto_card_button'>
                                        <button className='AdminPresupuesto_buttonR'>
                                            vista
                                        </button>
                                        <button className='AdminPresupuesto_buttonL'>
                                            finalizada
                                        </button>
                                    </div>

                                    </div>

                                </div> 
                                ))
                                
                        }

                </div>
            </div>
        )
    }

}