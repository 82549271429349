import React, { Component } from 'react'
import { render } from '@testing-library/react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Cards from './card';
import './carouselcards.css'
import axios from 'axios'
export default class CarouselCards extends Component {



  state={
    notes:[]
}

async getNotes() {
    const res = await axios.get('https://api.todoceramicas.com.uy/products/getProducts.php?&category='+this.props.tipo_id)
    this.setState({notes: res.data})
    console.log(res.data)
}

componentDidMount() {
    this.getNotes();
}

    render() {
      const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2
        }
      };
      var image_array;
    return(
      <div className='my-own-custom-container'>
          <Carousel showDots={false} className='carouselc_container' responsive={responsive} >
            {
              this.state.notes.map(note => (image_array = note.images.split(","), (
                <div key={note.product_id} className='cards_caro' >
                  
                  <Cards           
                      id={note.product_id}
                      title={note.name}
                      price={note.price}
                      oldprice={note.oldprice}
                      image={JSON.parse(note.images)[0]}
                      image2={JSON.parse(note.images)[1]}
                      model={note.model}
                      box_dim={note.box_dimension}
                  />
                </div> 
                ))) 
          }
          </Carousel>
        </div>  
    )
    }
}