import Modal from 'react-bootstrap/Modal';
import * as Icon from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import React, {useState} from 'react';
import Form from 'react-bootstrap/Form'
import './card.css'
import './Modal.css'
import ButtonEdit from './ButtonEdit'

function ModalPhoneCall() {

    const [show, setShow] = useState(false);
  
    const handleClose = () => {
        setShow(false);
        
    }
    const handleShow = () => setShow(true);


    return (
      <>
        <Button variant="outline-info" onClick={handleShow}>
            <Icon.Phone size={30}/> Telefono 
        </Button>
  
        <Modal className='modal_max' show={show} onHide={handleClose} animation={false}>
          <Modal.Header className='modal_header' closeButton>
            <Modal.Title className='modal_title' >PRESUPUESTO REALIZADO CON EXITO</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                Usted ah realizado el presupuesto con exito<br/>
                Comuniquese telefonicamente al: 2304 8805 <br/>
                O visite nuestra sucursal ubicada en Av. Millán 4373
          </Modal.Body>
          <Modal.Footer className='modal_footer'>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
export default ModalPhoneCall;