import React, { Component } from 'react'
import './category.css';
import axios from 'axios'
import Cards from '../containers/card';
import './Buscar.css';

export default class Buscar extends Component {
    
    state={
        notes:[],
        id: '',
    }
    

    async getNotes() {
        const res = await axios.get('https://api.todoceramicas.com.uy/products/getProduct.php?&name='+this.props.match.params.id)
        this.setState({
            notes: res.data,
            id: this.props.match.params.id
        })

    }
    
    componentDidMount() {
        window.scrollTo(0, 0)
        this.getNotes();
        document.title = 'Buscar' 
    }
    
    render(){
        if(this.state.id !== this.props.match.params.id){
            this.componentDidMount();
        }
        if(this.state.notes[0]){
        return(
            <div className='category_max'>
                <div className='buscar_title'>
                    Buscar por: {this.props.match.params.id}
                </div>
                <div className='category_container'>
                    
                {
                this.state.notes.map(note => (
                    <div key={note.product_id} className='cards_caro category_bottom' >
                    
                        <Cards           
                            id={note.product_id}
                            title={note.name}
                            price={note.price}
                            oldprice={note.oldprice}
                            image={JSON.parse(note.images)[0]}
                            image2={JSON.parse(note.images)[1]}
                            model={note.model}
                            box_dim={note.box_dimension}
                        />
                    </div> 
                )) 
                }
                </div>
                </div>
        )
    }else{
        return(
            <div className='category_max'>
            <div className='buscar_title'>
                Buscar por: {this.props.match.params.id}
            </div>
            <div className='buscar_title'>
                
                No se a encontrado elementos para su busqueda
            </div>
            </div>
        )
    }
    }


}