import React, {Component} from 'react';
import './checkout.css';
import Cookies from 'universal-cookie';
import CardCheckout from '../containers/cardContainer'
import Button from 'react-bootstrap/Button'
import * as Icon from 'react-bootstrap-icons';
import axios from 'axios'
import ModalPhoneCall from '../containers/ModalPhoneCall'
import { Link } from 'react-router-dom'

// Whatsapp info
const wpp_endpoint = 'https://api.whatsapp.com/send?phone=59895720000&text=';
const wpp_basic_msg = 'Hola, queria hacer una consulta';


export default class Checkout extends Component{

    componentDidMount(){
        window.scrollTo(0, 0)
        document.title = 'Checkout'
    }

    onClickButton = async origin =>{
        const cookies = new Cookies();
        const can_prods = cookies.get('cantidad');
        if(can_prods > 0){
            const subtot_car = await cookies.get('name');
            const cant = cookies.get('cantidad_carry');
            const total = cookies.get('subtotal');
            const precio_car = cookies.get('precio');
            const cajas = cookies.get('cajas');
            var i=0;
            var arreglo = [];
            if(subtot_car)
            subtot_car.map(note =>{
                arreglo.push({
                    'name' : note,
                    'cantidad': cant[i],
                    'precio': precio_car[i]*cant[i],
                    'cajas': cajas[i]
                    });
                i=i+1;
            }
            )
            var myJSON = JSON.stringify(arreglo);
            // console.log(myJSON)
            const newPresupuesto ={
                "products": arreglo,
                "price": total,
                "type": 0
            }
            var myJSON2 = JSON.stringify(newPresupuesto);
            const { order_id } = (await axios.post('https://api.todoceramicas.com.uy/orders/addOrder.php', myJSON2)).data;
            cookies.remove('id', {path: '/'});
            cookies.remove('name', {path: '/'});
            cookies.remove('cantidad_carry', {path: '/'});
            cookies.remove('subtotal', {path: '/'});
            cookies.remove('precio', {path: '/'});
            cookies.remove('cajas', {path: '/'});
            cookies.remove('cantidad',{path: '/'});
            if(origin == 'whatsapp'){
                var wpp_product_string = '';
                newPresupuesto.products.map((elem)=>{
                    wpp_product_string = wpp_product_string + "*Producto*: " + elem.name + "%0a*Metros cuadrados*: " + elem.cantidad + "%0a*Cajas*: " + elem.cajas + "%0a*Precio*: " + parseFloat(elem.precio).toFixed(2) + " %0a%0a"; 
                });
                window.location.href = wpp_endpoint + 'Queria consultar mi orden *' + order_id + '*:%0a%0a' + wpp_product_string + '*Precio final :* ' + parseFloat(newPresupuesto.price).toFixed(2); 
            }
        }
    }



    render() {
        const cookies = new Cookies();
        const res = cookies.get('id');
        const totalprod = cookies.get('cantidad');
        const cant = cookies.get('cantidad_carry');
        const model_car = cookies.get('model');
        const name_car = cookies.get('name');
        const precio_car = cookies.get('precio');
        const subtot_car = cookies.get('subtotal');
        const image_car = cookies.get('image')
        const boxes = cookies.get('cajas')
        var i=-1;
        var j=-1;
        if(totalprod > 0){
        return (
            <div>
                <div className='checkout_title_max'>
                    TU PRESUPUESTO
                </div>
                <div className='checkout'>

                    <div className='checkout_product'>
                        {
                            res.map(note => (
                                <div >
                                    <CardCheckout boxes={boxes[i + 1]} cantidad={cant[i = i + 1]} precio={parseFloat(precio_car[i]).toFixed(2)} image={image_car[i]} nombre={name_car[i]} model={model_car[i]} id={i} />
                                </div>

                            ))
                        }

                    </div>
                    <div className='checkout_voleta'>
                        <div className='checkout_cont_vol'>
                            <div className='checkout_border_bot'>
                                {
                                    res.map(note => (
                                        <div className='chekout_subtotal'>

                                            <div className='checkout_voleta_text'>
                                                {name_car[j = j + 1]}
                                            </div>
                                            <div className='checkout_voleta_price'>
                                                ${(precio_car[j] * cant[j]).toFixed(2)}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <div className='checkout_border_bot' >
                                <div className='chekout_subtotal'>
                                    <div className='checkout_voleta_text2'>
                                        Subtotal
                                </div>
                                    <div className='checkout_voleta_price2'>
                                        ${(subtot_car * 0.8197).toFixed(2)}
                                    </div>
                                    <div className='checkout_voleta_text'>
                                        IVA
                                </div>
                                    <div className='checkout_voleta_price'>
                                        ${(subtot_car * 0.1803).toFixed(2)}
                                    </div>
                                </div>
                            </div>
                            <div className='check_ad'>
                                <div className='chekout_subtotal'>
                                    <div className='checkout_voleta_text'>
                                        Total
                                    </div>
                                    <div className='checkout_voleta_price'>
                                        ${(subtot_car * 1).toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout_presu_title'>
                            Consultar Presupuesto
                    </div>
                        <div className='checkout_buttons'>
                            <div className='checkout_buttons2'>
                                <div className='checkout_button_separated2'><Button onClick={()=> this.onClickButton('whatsapp')} variant="outline-success"><Icon.Whatsapp size={30} /> WhatsApp </Button></div>
                                <div className='checkout_button_separated1' onClick={()=> this.onClickButton('phone')}><ModalPhoneCall /></div>
                            </div>

                        </div>
                        <div className='checkout_buttons'>
                            <Link to='' className='checkout_buttons2'>
                                <button type="button" className="btn btn-outline-secondary">Seguir comprando</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else{
        return(
            <div>
                <div className='checkout_title_max'>
                    TU PRESUPUESTO
                </div>
                <div className='checkout'>

                    <div className='checkout_product'>
                        <div className='checkout_presu_title'>
                            Aún no has agregado nada a tu presupuesto
                </div>
                        <div>
                            Te recordamos que no estas realizando ninguna compra, estas armando tu propio presupuesto para facilitar la compra.
                            Luego tendras que comuncarte con nosotros, ya sea via WhatsApp o Telefono
                        </div>

                    </div>
                    <div className='checkout_voleta'>
                        <div className='checkout_cont_vol'>
                            <div className='checkout_border_bot'>

                            </div>
                            <div className='checkout_border_bot' >
                                <div className='chekout_subtotal'>
                                    <div className='checkout_voleta_text2'>
                                        Subtotal
                            </div>
                                    <div className='checkout_voleta_price2'>
                                        ${0.0}
                                    </div>
                                    <div className='checkout_voleta_text'>
                                        IVA
                                    </div>
                                    <div className='checkout_voleta_price'>
                                        ${0.0}
                                    </div>
                                </div>
                            </div>
                            <div className='check_ad'>
                                <div className='chekout_subtotal'>
                                    <div className='checkout_voleta_text'>
                                        Total
                                    </div>
                                    <div className='checkout_voleta_price'>
                                        ${0.0}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout_presu_title'>
                            Consultar Presupuesto
                        </div>
                        <div className='checkout_buttons'>
                            <div className='checkout_buttons2'>
                                <div className='checkout_button_separated2'><Button href={wpp_endpoint + wpp_basic_msg } variant="outline-success"><Icon.Whatsapp size={30} /> WhatsApp </Button></div>
                                <div className='checkout_button_separated1'><Button variant="outline-info"><Icon.Phone size={30} /> Telefono  </Button></div>
                            </div>
                        </div>
                        <div className='checkout_buttons'>
                            <Link to='' className='checkout_buttons2'>
                                <button type="button" className="btn btn-outline-secondary">Seguir comprando</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    }
}