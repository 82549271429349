import React, { Component } from 'react'
import CardAdmin from './cardAdmin'
import Cookies from 'universal-cookie';

export class Posts extends Component {

    onCheckChange = e =>{
        const cookies = new Cookies();
        let newCat = cookies.get('admincheckdelete');
        if( newCat !== undefined){
        if(!newCat.includes(e.target.value)){
          newCat.push(e.target.value);
          cookies.set('admincheckdelete', newCat, {path: '/'});
        }else{
          var i= newCat.indexOf(e.target.value);
          newCat.splice(i,1);
          cookies.set('admincheckdelete', newCat, {path: '/'});
        }
        }else{
            newCat = [e.target.value]
            cookies.set('admincheckdelete', newCat, {path: '/'});  
        }
    }


    render() {
        const { posts, loading } = this.props;

        if (loading) {
            return <h2>Cargando...</h2>
        }
        console.log(posts)
        return (
            <div>
                {posts.map(post => (
                    <div key={post.product_id}>
                        <div className='Ceramicas_margin'>
                        <input  className="form-check-input ceramicas_check"
                                type="checkbox"
                                id="inlineCheckbox1"
                                value={post.product_id}
                                onChange={this.onCheckChange}
                        ></input>
                        {console.log(post)}
                            <CardAdmin
                                id={post.product_id}
                                title={post.name}
                                price={post.price}
                                image={JSON.parse(post.images)[0]}
                                model={post.model}
                                box_dim={post.box_dimension}
                                status={post.status}
                            />
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

export default Posts