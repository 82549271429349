import './BannerAdmin.css';
import React, {Component} from 'react';
import carousel1 from '../Banner.png'
import Form from 'react-bootstrap/Form'
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner'

export default class BannerAdmin extends Component{

    state= {
        imagenes: [],
        cargando: false,
        banners: [],
        url: '',
        link: '',
        id: ''
      }

      async componentDidMount(){
        const cat = await axios.get('https://api.todoceramicas.com.uy/banners/getBanners.php')
        this.setState({
            banners: cat.data,
        })
    }


    onChange = async (e) => {
        await this.setState({
          imagenes: e.target.files[0]
      })
    }

    onClickButtonDelete= async (e) =>{
        const newNote ={
            id: e.target.value,
            link: this.state.link,
            url: ''
        }
        const json = await JSON.stringify(newNote);
        const resjson = await axios.put('https://api.todoceramicas.com.uy/banners/editBanner.php?&id='+e.target.value,json)
        console.log(resjson)
        this.componentDidMount()
    }

    onClickButton = async (e) =>{
        e.preventDefault();
        console.log(e.target.value)
        console.log(this.state.imagenes)
        const fromData = new FormData();
        fromData.append( 
          "image", 
          this.state.imagenes, 
          this.state.imagenes.name 
        )
        const res = await axios.post('https://api.todoceramicas.com.uy/images/banners/addImage.php', fromData);
        console.log(res.data);
        let newNote
        res.data.map(urladd => (
            newNote ={
                id: e.target.value,
                link: this.state.link,
                url: urladd
            }
        ))

        const json = await JSON.stringify(newNote);
        const resjson = await axios.put('https://api.todoceramicas.com.uy/banners/editBanner.php?&id='+e.target.value,json)
        console.log(resjson)
        this.componentDidMount()
    }


    render(){
        return(
            <div className='BannerAdmin'>
                {this.state.banners.map((banner, index) => (
                    <div key={index} className='BannerAdmin_container' >
                        <div className='BannerAdmin_title'>
                            Banner {index +1}
                        </div>
                        <img className='BannerAdmin_img' src={banner.url} alt="" />
                        <div className='BannerAdmin_cont'>
                            
                            <div className='BannerAdmin_input'>
                                <Form.Group>
                                    <Form.Control type='file' name='images' multiple onChange={this.onChange} />
                                </Form.Group>
                            </div>
                            <div className='BannerAdmin_save'>
                                <button type="button" className="btn btn-danger" value={index+1} onClick={this.onClickButtonDelete}>Eliminar</button>
                                <button type="button" className="btn btn-success BannerAdmin_margin" value={index+1} onClick={this.onClickButton}>Guardar</button>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        )
    }

}