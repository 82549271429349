import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.css'
import facebook from './facebook.png';
import instagram from './instagram.png';
import Direccion from './Direccion1.png';
import * as Icon from 'react-bootstrap-icons';
import LogoBPA from './LogoBPA.png'

const FooterPage = () => {
  return (
    <div className='footer'>
      <div>
        <MDBFooter className="footer__letra footer__back font-small pt-4 mt-4">
          <MDBContainer fluid className="footer_cont text-center text-md-left">
            <MDBRow className='footer__margin '>
              <MDBCol className='footer_lineado' md="4">
                <h5 className="footer_title">Nuestro Local</h5>

                <p>
                Horario: Lunes a Viernes: 9:00 a 18:00
                        Sabados: 9:00 a 13:00
                        </p>
                <p>
                        Teléfono: 2304 8805

                </p>
              </MDBCol>
              <MDBCol className='footer_lineado' md="4">
                <h5 className="footer_title">Redes Sociales</h5>
                <ul className='footer__orden'>
                  <li className="list-unstyled footer__icon">
                    <a href="https://www.facebook.com/ceramicasuruguay">
                      <img src={facebook} alt="" className='imagenfb'/>
                    </a>
                  </li>
                  <li className="list-unstyled footer__icon2">
                    <a href="https://www.instagram.com/ceramicasuy/">
                    <img src={instagram} alt="" className='imagenig'/>
                      </a>
                  </li>
                </ul>
                <h5 className="footer_title2">Envios</h5>
                <ul className='footer__orden'>
                  <div className='footer_truck'>
                  <Icon.Truck color="#ffffff" size={38}/>
                  <div className='footer_envios'>ENVIAMOS A TODO EL PAIS</div> 
                  </div>
                </ul>
              </MDBCol>
              <MDBCol md="4">
                <h5 className="footer_title">Donde Estamos</h5>
                <p className='footer_dirname'>
                Av. Millán 4373, Montevideo
                </p>
                <ul className='footer__orden'>
                  <a href="https://www.google.com/maps/place/Ceramicas.uy/@-34.8448656,-56.2067856,17z/data=!3m1!4b1!4m5!3m4!1s0x95a1d5333bdb529b:0x70d4d3b82b4c3149!8m2!3d-34.8448656!4d-56.2045969">
                    <img className='footer_direccion' src={Direccion} alt=""/>
                    </a>
                </ul>
              </MDBCol>
              
            </MDBRow>
          </MDBContainer>
          <div className="footer__inf footer-copyright text-center py-3">
            <MDBContainer fluid>
              &copy; {new Date().getFullYear()} Developed by 
              <a href="tel:598-99-957-789">
                <img className='footer_logo_BPA' src={LogoBPA} alt=""/>
              </a>
            </MDBContainer>
          </div>
        </MDBFooter>
      </div>
    </div>
  );
}

export default FooterPage;