import {BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header'
import Banner from './components/Banner'
import Ambiente from './components/Ambiente'
import Home from './components/Home'
import FooterPage from './components/footer'
import Pagos from './containers/pago'
import WhatsApp from './components/wpp'
import Checkout from './components/checkout'
import Product from './components/product'
import CategoryProducts from './components/category'
import AdminHome from './components/AdminHome'
import Buscar from './components/Buscar'
import Login from './components/admin/Login'
import PruebaCards from './containers/pruebacars'
import ReactGA from 'react-ga';
import { useEffect } from 'react';

function usePageViews() {
  const TRACKING_ID = "G-SCT55MHMG9";
	let location = window.location;
	useEffect(() => {
		ReactGA.initialize(TRACKING_ID);
		ReactGA.set({ page: location.pathname });
		ReactGA.pageview(location.pathname);
	}, [location]);
}

export default function App() {
  usePageViews()
  return (
    <Router>
    <div className="App">
      <Route path='/' exact>
        <Header/>
        <WhatsApp/>
        <Ambiente/>
        <Banner/>
        <Home/>
        <Pagos/>
       <FooterPage/> 
      </Route>
      <Route path='/checkout'>
        <Header/>
        <WhatsApp/>
        <Ambiente/>
        <Checkout/>
        <Pagos/>
        <FooterPage/> 
      </Route>
      <Route path="/product/:id">
        <Header/>
        <WhatsApp/>
        <Ambiente/>
        <Route path="/product/:id" component={Product} />
        <Pagos/>
       <FooterPage/> 
      </Route>
      <Route path="/category/:id">
      <Header/>
        <WhatsApp/>
        <Ambiente/>
        <Route path="/category/:id" component={CategoryProducts}/>
        <Pagos/>
       <FooterPage/> 
      </Route>
      <Route path="/buscar/:id">
      <Header/>
        <WhatsApp/>
        <Ambiente/>
        <Route path="/buscar/:id" component={Buscar} />
        <Pagos/>
       <FooterPage/> 
      </Route>
      <Route path="/admin" exact>
        <Login/>
      </Route>
      <Route path="/admin/inicio" exact>
        <AdminHome location='/inicio'/>
      </Route>
      <Route path="/admin/ceramicas" >
        <AdminHome location='/ceramicas'/>
      </Route>
      <Route path="/admin/presupuestos">
        <AdminHome location='/presupuestos'/>
      </Route>
      <Route path="/admin/carousel">
        <AdminHome location='/carousel'/>
      </Route>
      <Route path="/admin/banners">
        <AdminHome location='/banners'/>
      </Route>
      <Route path="/prueba">
        <PruebaCards/>
      </Route>
      
    </div>
    </Router>
  );
}
