import './cardContainer.css'
import ButtonDelete from './ButtonDelete'
import ModalEdit from './ModalEdit'

function CardCheckout({nombre,precio,boxes,cantidad,id,model,image}) {
    if(window.matchMedia("(min-width: 768px)").matches){ 
    return (
        <div className='cardCheck_container_abs'>
            <div className='cardCheck_container'>
                <div className='cardCheck_imgcont'>
                    <img className='cardCheck_img' src={image} alt="" />
                </div>
                <div className='cardCheck_info'>
                    <div>
                        {nombre}
                    </div>
                    <div className='cardCheck_cantidad cardCheck_cel'>
                        {model}
                    </div>
                    <div className='cardCheck_cantidad cardCheck_espaciado'>
                        Cajas: {boxes}
                    </div>
                    <div className='cardCheck_cantidad '>

                        <div className='cardCheck_cantidad'>
                            Precio por m²: ${precio}
                        </div>
                        <div className='cardCheck_cantidad2'>
                            Cantidad: {cantidad}m²
                        </div>
                    </div>
                    <div className='cardCheck_espaciado'>
                        Sub Total: ${(precio * cantidad).toFixed(2)}
                    </div>
                    <div className='cardCheck_button'>
                        <div className='cardCheck_button_espaciado'><ButtonDelete idBorrar={id} /></div>
                        <ModalEdit idAgregar={id} />
                    </div>
                </div>

            </div>
        </div>
    )}else{
        return(
            <div className='cardCheck_container_abs'>
                <div className='cardCheck_container'>
                    <div className='cardCheck_imgcont'>
                        <img className='cardCheck_img' src={image} alt="" />
                    </div>
                    <div className='cardCheck_info'>
                        <div>
                            {nombre}
                        </div>
                        <div className='cardCheck_font_weight'>
                            {model}
                        </div>
                        <div className='cardCheck_font_weight'>
                            Cajas: {boxes}
                        </div>

                        <div className='cardCheck_font_weight'>
                            Precio por m²: ${precio}
                        </div>
                        <div className='cardCheck_font_weight'>
                            Cantidad: {cantidad}m²
                        </div>

                        <div className='cardCheck_espaciado'>
                            Sub Total: ${(precio * cantidad).toFixed(2)}
                        </div>
                        <div className='cardCheck_button'>
                            <div className='cardCheck_button_espaciado'><ButtonDelete idBorrar={id} /></div>
                            <ModalEdit idAgregar={id} />
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default CardCheckout;