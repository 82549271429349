import React, {Component} from 'react';
import './prueba.css';
import axios from 'axios'

export default class Prueba extends Component{

    state={
        product: [],
        img: '',
        set: false,
        images: '',
        fotos: [],
        _id: ''
    }

    constructor(props) {
        super(props)
        this.state = { matches: window.matchMedia("(max-width: 768px)").matches };
    }

    async componentDidMount() {
        const handler = e => this.setState({matches: e.matches});
        window.matchMedia("(max-width: 768px)").addListener(handler);
        const res = await axios.get('https://api.todoceramicas.com.uy/products/getProduct.php?&id=' + this.props.id)
        this.setState({
            product: res.data,
            _id: this.props.id,
        })
        await this.state.product.map(note =>(
            this.setState({
                images: note.images
            })
        ))
        await this.setState({
            fotos: JSON.parse(this.state.images)
        })
        const array= this.state.fotos
        await this.setState({
            img: array[0]
        })
    }

      onClick = async (e) =>{
        //console.log(e.target.id)
        if(e.target.id === ''){
        await this.setState({
            img: e.target.src
        })
        }else{
            await this.setState({
                img: this.state.fotos[e.target.id]
            }) 
        }
      }



    
    render(){
        if(this.props.id != this.state._id){
            this.componentDidMount()
        }
        const prueba = this.props.imagenes
        const galery_array=[];
        if(prueba){
        prueba.map(value =>(
            galery_array.push(value)
        ))
        }
        


        return(
            <div className='Gal_container'>
                <div className='Gal_lista'>
                    <div>
                    {galery_array.map((note, index) =>(
                        <div key={index} className='Gal_list_img' id={index} onClick={this.onClick}>
                            <img src={note} alt=""/>
                        </div>
                    ))}
                    </div>
                </div>
                <div className='Gal_img'>
                        <div className='Gal_img_img'>
                            <img src={this.state.img} alt=""/>
                        </div>
                </div>
            </div>
        )
    }
}