import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import './NewProduct.css'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import * as Icon from 'react-bootstrap-icons';
import { Link, Redirect } from 'react-router-dom'
import { DriveEtaSharp } from '@material-ui/icons';

let agregarBot = 'Agregar'

class NewProduct extends Component {

  state = {
    show: false,
    nombre: '',
    precio: '',
    imagenes: [],
    categoria: [],
    modelo: '',
    ancho: '',
    largo: '',
    boxdim: '',
    oldprice: '',
    description: '',
    sort: '',
    promotion: false,
    alerta: false,
    mensaje: false,
    arregloimagenes: [],
    finalizo: false,
    category_parent: [],
    enable: true,
    cp_edit: []
  }

  async componentDidMount() {
    const cat = await axios.get('https://api.todoceramicas.com.uy/categories/getCategories.php?&parent_id=1')
    if (this.props.editid) {
      const res = await axios.get('https://api.todoceramicas.com.uy/products/getProduct.php?&id=' + this.props.editid);
      const cat2 = await axios.get('https://api.todoceramicas.com.uy/categories/getCategories.php?product_id='+this.props.editid)
      const categorias = []
      await this.addCategories(cat2.data)
      
      const porpd_id = res.data[0]
      this.setState({
        show: false,
        nombre: porpd_id.name,
        precio: porpd_id.price,
        imagenes: [],
        modelo: porpd_id.model,
        ancho: porpd_id.width,
        largo: porpd_id.length,
        boxdim: porpd_id.box_dimension,
        description: porpd_id.description,
        sort: porpd_id.sort,
        promotion: porpd_id.promoted,
        oldprice: porpd_id.oldprice,
        imagenesguar: JSON.parse(porpd_id.images),
        alerta: false,
        mensaje: false,
        arregloimagenes: porpd_id.images,
        finalizo: false,
        enable: porpd_id.status,
        category_parent: cat.data,
        cp_edit: []
      })
    } else
      this.setState({
        show: false,
        nombre: '',
        precio: '',
        imagenes: [],
        categoria: [],
        modelo: '',
        ancho: '',
        largo: '',
        boxdim: '',
        oldprice: '',
        description: '',
        sort: '',
        promotion: false,
        imagenesguar: [],
        alerta: false,
        mensaje: false,
        arregloimagenes: [],
        finalizo: false,
        category_parent: cat.data,
        cp_edit: []
      })
      console.log(this.state.categoria)
  }

  async addCategories(e) {
    let categorias = []
    for(let i in e)
        categorias.push(e[i].name)
    this.setState({
      categoria: categorias
    })
  }

  handleShow = e => {
    this.setState({
      show: true
    })
  }

  handleClose = e => {
    this.setState({
      show: false
    })
    this.componentDidMount()
  }

  onChange = async (e) => {
    await this.setState({
      imagenes: e.target.files,
    })
    console.log(this.state.imagenes)
  }

  onInputChange = (e) => {
    console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onSelectChange = async (e) => {
    const parent_cat = ["Pisos", "Paredes", "Loza sanitaria", "Accesorios", "Promociones"]
    await this.setState({
      category_parent: [],
      categoria: [parent_cat[e.target.value - 1]]
    })
    const cat = await axios.get('https://api.todoceramicas.com.uy/categories/getCategories.php?&parent_id=' + e.target.value)
    await this.setState({
      category_parent: cat.data
    })
  }


  onCheckChange = async (e) => {
    const newCat = this.state.categoria;
    console.log(newCat)
    if (!newCat.includes(e.target.id)) {
      newCat.push(e.target.id);
    } else {
      var i = newCat.indexOf(e.target.id);
      newCat.splice(i, 1);
    }
    this.setState({
      categoria: newCat
    })
    if (e.target.id === 'Promociones')
      this.setState({
        promotion: !this.state.promotion
      })
  }

  onCheckChangeEable = async (e) => {
    this.setState({
      enable: !this.state.enable
    })
  }


  onSubmit = async (e) => {
    e.preventDefault();
    agregarBot = <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    />
    let cuenta = 0;
    await this.setState({
      alerta: false
    })
    const image1 = this.state.imagenes;
    for (let index = 0; index < image1.length; index++) {
      const fromData = new FormData();
      fromData.append(
        "image",
        this.state.imagenes[index],
        this.state.imagenes[index].name
      )
      const res = await axios.post('https://api.todoceramicas.com.uy/images/ceramicas/addImage.php', fromData);
      console.log(res);
      let posicion = res.data.indexOf('Fail');
      if (posicion !== -1)
        console.log("Error");
      else {
        const algo = this.state.imagenesguar
        await algo.push(res.data)
        await this.setState({
          imagenesguar: algo
        })
        cuenta = cuenta + 1;
      }


    }
    if (this.state.imagenesguar != undefined) {
      const jsonimg = await JSON.stringify(this.state.imagenesguar);
      const jsoncat = await JSON.stringify(this.state.categoria);
      const newNote = {
        nombre: this.state.nombre,
        precio: this.state.precio,
        oldprice: this.state.oldprice,
        imagenes: jsonimg,
        categoria: jsoncat,
        modelo: this.state.modelo,
        ancho: this.state.ancho,
        largo: this.state.largo,
        promoted: this.state.promotion,
        boxdim: this.state.boxdim,
        description: this.state.description,
        sort: this.state.sort,
        status: this.state.enable
      }
      const json = await JSON.stringify(newNote);
      if (this.props.editid) {
        const resjson = await axios.post('https://api.todoceramicas.com.uy/products/editProduct.php?&id=' + this.props.editid, json)
      } else {
        const resjson = await axios.post('https://api.todoceramicas.com.uy/products/addProduct.php', json)
      }

      this.componentDidMount()
      this.setState({
        show: false
      })

      window.location.reload(false);
    } else {
      agregarBot = 'Agregar'
      this.setState({
        alerta: true
      })
    }
  }

  render() {
    let array = []
    for (let index = 0; index < this.state.imagenes.length; index++) {
      array.push(URL.createObjectURL(this.state.imagenes[index]))
    }
    const categoria1 = ['Pisos', 'Paredes']
    let texto = <Button variant="primary" onClick={this.handleShow} className='NewProduct_modal'>
      Agregar Nueva
    </Button>
    if (this.props.editid) {
      texto = <button className='btn btn-outline-primary CardAdmin_margin' onClick={this.handleShow}><Icon.PencilFill size={20} /></button>
    }

    return (

      <>

        {texto}
        <Modal show={this.state.show} className='NewProduct_modal_marg' onHide={this.handleClose} animation={false}>
          {(() => {
            if (this.state.alerta) {
              return (
                <Alert variant='danger' className='NewProduct_alert'>
                  Error al cargar las imagenes
                </Alert>
              )
            }
          })()}
          <Modal.Header className='modal_header' closeButton>
            <Modal.Title className='modal_title' >AGREGAR PRODUCTO:</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form>
              <Form.Group>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  name="nombre"
                  onChange={this.onInputChange}
                  value={this.state.nombre}
                  required />
              </Form.Group>
              <Form.Group>
                <Form.Label>Precio</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Precio"
                  name="precio"
                  onChange={this.onInputChange}
                  value={this.state.precio}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Precio Anterior</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Precio Anterior"
                  name="oldprice"
                  onChange={this.onInputChange}
                  value={this.state.oldprice}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Modelo</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Modelo"
                  name="modelo"
                  onChange={this.onInputChange}
                  value={this.state.modelo}
                  required
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Habilitado</Form.Label>
                <Form>
                  <div className="mb-3">
                    <Form.Check
                      key='1'
                      checked={this.state.enable == 1 ? "checked" : ''}
                      inline
                      label='Habilitado'
                      type='checkbox'
                      id='Habilitado'
                      value={this.state.enable == 1}
                      onChange={this.onCheckChangeEable} />
                  </div>
                </Form>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlSelect2">
                <Form.Label>Categoria</Form.Label>
                <Form>
                  <div className="mb-3">
                    {categoria1.map((type) => (
                      <Form.Check
                        key={type.category_id}
                        checked = {this.state.categoria.includes(type)}
                        inline
                        label={type}
                        type='checkbox'
                        id={type}
                        value={this.state.categoria}
                        onChange={this.onCheckChange} />
                    ))}
                  </div>
                </Form>
              </Form.Group>
              {(() => {
                if (this.state.category_parent.length > 0) {
                  return (
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Categoria 2</Form.Label>
                      <Form>
                        <div className="mb-3">
                          {this.state.category_parent.map((type) => (
                            <Form.Check
                              key={type.category_id}
                              checked = {this.state.categoria.includes(type.name)}
                              inline
                              label={type.name}
                              type='checkbox'
                              id={type.name}
                              value={this.state.categoria}
                              onChange={this.onCheckChange} />
                          ))}
                        </div>
                      </Form>
                    </Form.Group>)
                }
              })()}
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Promociones</Form.Label>
                <Form>
                  <div className="mb-3">
                    <Form.Check
                      key='1'
                      inline
                      label='Promociones'
                      type='checkbox'
                      id='Promociones'
                      value={this.state.promotion}
                      onChange={this.onCheckChange} />
                  </div>
                </Form>
              </Form.Group>
              <Form.Group>
                <Form.Label>Ancho</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Ancho"
                  name="ancho"
                  onChange={this.onInputChange}
                  value={this.state.ancho}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Largo</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Largo"
                  name="largo"
                  onChange={this.onInputChange}
                  value={this.state.largo}
                  required />
              </Form.Group>
              <div className='NewProduct_description'>
                <div>Descripcion</div>
                <div>
                  <textarea
                    name="description"
                    cols="60"
                    rows="7"
                    onChange={this.onInputChange}
                    value={this.state.description}
                  ></textarea>
                </div>
              </div>
              <Form.Group>
                <Form.Label>m² por caja</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="m² por caja"
                  name="boxdim"
                  onChange={this.onInputChange}
                  value={this.state.boxdim}
                  required />
              </Form.Group>
              <Form.Group>
                <Form.Label>Orden</Form.Label>
                <Form.Control
                  type="sort"
                  placeholder="Orden"
                  name="sort"
                  onChange={this.onInputChange}
                  value={this.state.sort}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Control type='file' name='images' multiple onChange={this.onChange} />
              </Form.Group>
              <div className='NewProduct_prev_img_cont'>
                {

                  array.map((value, index) => (
                    <div>
                      <img className='NewProduct_prev_img' src={value} alt="" />
                      <div className='NewProduct_img_number'>
                        {index + 1}
                      </div>
                    </div>
                  ))
                }
              </div>
            </Form>



          </Modal.Body>
          <Modal.Footer className='modal_footer'>
            <Button variant="secondary" onClick={this.handleClose}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={this.onSubmit}>
              {agregarBot}
            </Button>

          </Modal.Footer>
        </Modal>
      </>


    )
  }
}

export default NewProduct
