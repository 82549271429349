import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import './card.css'
import React from "react";
import './publicidad.css';
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom'
import ModalButton from './Modal.js'

function Cards({ id, title, price, oldprice, image, image2, model, box_dim }) {
    if (image2 == null) {
        image2 = image;
    }
    if (window.matchMedia("(min-width: 768px)").matches) {
        return (
            <div className='card-body'>
                <div className='cards_promotion'>
                    Oferta!
                </div>
                <div className='cursor visible2'>
                    <Card className='card__container cards__cont'>
                        <Link to={"/product/" + id}>
                            <div className='cards__img_cont'>
                                <Card.Img className='cards__img cards__cont' variant="botton" src={image2} />
                            </div>

                        </Link>
                        <Card.Body className='card__icons cards__body cards__cont'>
                            <Link to={"/product/" + id}>
                                <Button variant="warning" className='cards__cont icon1'>
                                    <Icon.Search color="black" size={30} />
                                </Button>
                            </Link>
                            <ModalButton idAgregar={id} model={model} box_dim={box_dim} name={title} image={image} price_m={price} />
                        </Card.Body>
                    </Card>
                </div>
                <div className='cursor visible'>
                    <Card className='card__container cards__cont'>
                        <div>
                            <div className='cards__img_cont'>
                                <Card.Img className='cards__img cards__cont' variant="botton" src={image} />
                            </div>
                            <Card.Body className='cards__body cards__cont'>
                                <Card.Text className='cards__title cards__cont'>{title}</Card.Text>
                                {(() => {
                                    if (oldprice != 0) {
                                        return (
                                            <div>
                                                <Card.Text className='card_old_price cards__cont'>${oldprice}</Card.Text>
                                                <Card.Text className='card_now_price cards__cont'>Ahora</Card.Text>
                                            </div>
                                        )
                                    }
                                })()}
                                <Card.Title className='cards__price cards__cont'>${price}</Card.Title>
                            </Card.Body>
                        </div>

                    </Card>
                </div>
            </div>
        );
    } else {
        return (
            <div className='card_mob_body'>
                <div className='card_mob_container'>
                    <Link className='card_mob_img' to={"/product/" + id}>
                        <img src={image} alt="" />
                    </Link>
                    <div className='card_mob_footer'>
                        <Link className='card_mob_footer_title' to={"/product/" + id}>
                            <div className='card_mob_footer_title'>
                                {title}
                            </div>
                        </Link>
                        <div className={oldprice === '0' ? 'card_mob_oldprice2' : 'card_mob_oldprice'}>
                            ${oldprice}
                        </div>
                        <div className='card_mob_price'>
                            ${price}
                        </div>
                        <div>
                            <ModalButton idAgregar={id} model={model} box_dim={box_dim} name={title} image={image} price_m={price} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Cards;