import IconoWpp from './WhatsApp.png';
import './wpp.css';

function WhatsApp() {
    return(
        <div className='wpp__fixed' >
            <a href="https://api.whatsapp.com/send?phone=59895720000&text=Hola!%20Quiero%20hacer%20una%20consulta.">
                <img className='wpp__icon' src={IconoWpp} alt=""/>
            </a>
        </div>
    );
}

export default WhatsApp;