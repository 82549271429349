import React, { Component } from 'react';
import './cards2.css'
import '../containers/card.css'
import { Link } from 'react-router-dom'
import ModalButton from './Modal.js'
import * as Icon from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button'

export default class cars2 extends Component {

    state = {
        over: false
    }
    componentDidMount() {
        this.setState({
            over: false
        })
    }

    onMouseOverOne = e => {
        this.setState({
            over: true
        })
    }

    onmouseout = e => {
        this.setState({
            over: false
        })
    }

    render() {

        const valor = this.props.value
        const img = JSON.parse(this.props.value.images)
        const image1 = img[0]
        const image2 = img[1]
        return (
            <div onMouseOver={this.onMouseOverOne} onMouseLeave={this.onmouseout} className='cards2_img_max'>
                <div className='cards2_border'>
                    <div className={valor.promoted === '1' ? 'cards2_img_oferta' : 'cards2_oferta_off'}>
                        Oferta!
                    </div>
                    {(() => {
                        if (this.state.over) {
                            return (
                                <div>
                                    <Link to={"/product/" + valor.product_id} className='cards2_img_cont'>
                                        <img className='cards2_img' src={image2} alt="" />
                                    </Link>
                                    <div className='cards2_body_cont2'>
                                        <div className='cards2_body_cont_butt'>
                                            <Link to={"/product/" + valor.product_id}>
                                                <Button variant="warning" className='cards__cont icon1'>
                                                    <Icon.Search color="black" size={30} />
                                                </Button>
                                            </Link>
                                            <ModalButton idAgregar={valor.product_id} model={valor.model} box_dim={valor.box_dimension} name={valor.name} image={image1} price_m={valor.price} />

                                        </div>
                                    </div>
                                </div>

                            )
                        } else {
                            return (
                                <div>
                                    <Link to={"/product/" + valor.product_id} className='cards2_img_cont'>
                                        <img className='cards2_img' src={image1} alt="" />
                                    </Link>
                                    <div className='cards2_body_cont'>
                                        <div className='cards__title'>
                                            {valor.name}
                                        </div>
                                        {(() => {
                                            if (valor.oldprice != 0) {
                                                return (
                                                    <div>
                                                        <div className='card_old_price'>${valor.oldprice}</div>
                                                        <div className='card_now_price'>Ahora</div>
                                                    </div>
                                                )
                                            }
                                        })()}
                                        <div className='cards__price'>
                                            ${valor.price}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })()}
                </div>
            </div>

        )
    }
}
