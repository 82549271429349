import './AdminHome.css'
import React, {Component} from 'react';
import foto from './logo.png'
import Ceramicas from './admin/Ceramicas'
import AdminInicio from './admin/Inicio'
import Presupuestos from './admin/Presupuestos'
import CarouselAdmin from './admin/CarouselAdmin'
import BannerAdmin from './admin/BannerAdmin'
import {Link} from 'react-router-dom'
import Cookies from 'universal-cookie';
import Login from './admin/Login'

export default class AdminHome extends Component{


    onClickButton = async (e) =>{
        const cookies = new Cookies();
        cookies.set('adminLogin', false, {path: '/'});
        window.location.href='./';
    }

    render(){
        let pathname = this.props.location;
        let clase;
        if(pathname === "/ceramicas") {
            clase=<Ceramicas/>;
        }
        if(pathname === "/inicio") {
            clase=<AdminInicio/>;
        }
        if(pathname === "/presupuestos") {
            clase=<Presupuestos/>;
        }
        if(pathname === "/carousel") {
            clase=<CarouselAdmin/>;
        }
        if(pathname === "/banners") {
            clase=<BannerAdmin/>;
        }

        const cookies = new Cookies();
        var adminLogin = cookies.get('adminLogin');
        if(adminLogin === 'true'){
        return(
            <div>
                <div className='adminHome_header'>
                    <div className='adminHome_header_button'>
                        <button  type="button" className="btn btn-primary" onClick={this.onClickButton}>Salir</button>
                    </div>
                </div>
                <div className='adminHome_container'>

                    <div className='adminHome_bar'>
                        <Link to=''>
                        <img className='adminHome_img' src={foto} alt=""/>
                        </Link> 
                            <div className='adminHome_bar_container'>
                                <Link className={pathname === "/inicio" ? 'clas_border adminHome_route_in' : 'clas_border'} to="/admin/inicio">
                                    Inicio
                                </Link>
                                <Link className={pathname === "/ceramicas" ? 'clas_border adminHome_route_in' : 'clas_border'} to="/admin/ceramicas" >
                                    Ceramicas
                                </Link>
                                <Link className={pathname === "/presupuestos" ? 'clas_border adminHome_route_in' : 'clas_border'} to="/admin/presupuestos">
                                    Presupuestos
                                </Link>
                                <Link className={pathname === "/carousel" ? 'clas_border adminHome_route_in' : 'clas_border'} to="/admin/carousel">
                                    Carousel
                                </Link>
                                <Link className={pathname === "/banners" ? 'clas_border adminHome_route_in' : 'clas_border'} to="/admin/banners">
                                    Banners
                                </Link>
                                <div className='clas_border_botton'>
                                    _
                                </div>
                            </div>
                    </div>
                    <div className='adminHome_component'>
                        {clase}
                    </div>
                    
                </div>
            </div>
        )
    }else{
        return(
            <Login inicio='autenticate'/>
        )
    }
    }
}