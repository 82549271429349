import React, {Component} from 'react';
import '../components/Header.css'
import {Link, Redirect } from 'react-router-dom'
import * as Icon from 'react-bootstrap-icons';
import PropTypes from "prop-types";
import { FormatListNumberedRounded } from '@material-ui/icons';



export default class SearchProp extends Component{

    state={
        buscador: '',
        enter: false
    }



    SearchActual(e){
        this.setState({
            buscador: e
        })
    }

    handleKeyDown = async (e) => {  
        if(e.key==='Enter'){
           await this.setState({
               enter: true
           })
        }
    }

    onInputChange = async e =>{
        await this.SearchActual(e.target.value)
    }

    Onclick = async (e) =>{
        e.preventDefault();

    }


    render(){
        if(this.state.enter){
            return <Redirect to={'/buscar/'+this.state.buscador} />;
        }else{
        const search= this.props.search;
        if(window.matchMedia("(min-width: 768px)").matches){ 
        return(
            <div>
                <div className="header__search" >
                    <input type="text" 
                    className="header__searchInput"
                    placeholder=""
                    name="buscador"
                    onChange={this.onInputChange}
                    onKeyDown={this.handleKeyDown}
                    value={this.state.buscador}
                    required/>
                        <Link  to={'/buscar/'+this.state.buscador}>
                            <Icon.Search className='header__searchIcon' size={23} />
                        </Link>
                    </div>
            </div>
        )}else{
            return(
                <div className={search ? 'search_mobil2' : 'search_mobil_off'}>
                <input className='search_mobil3'
                 type="text" 
                 name="buscador"
                 onChange={this.onInputChange}
                 value={this.state.buscador}
                 required
                 />
                <div className='header_search_mob_left'>
                    <Link to={'/buscar/'+this.state.buscador}>
                        <Icon.Search size={15} color={search ? 'rgb(26, 26, 59)' : 'white'} />
                    </Link>
                </div>
                
            </div>
            )
        }
    }
}
}