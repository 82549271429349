import React, { Component } from 'react'
import './product.css';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Galery from '../containers/galery'
import axios from 'axios'
import CarouselCards from '../containers/carousercards'
import Titulo from'../containers/titulo'
import ButtonCookie from '../containers/ButtonCookie'
import {Link} from 'react-router-dom'
import Prueba from './prueba'



export default class Product extends Component {
    
    state= {
        users:[],
        _id: '',
        name:'',
        product_id: '',
        model: '',
        price: '',
        width: '',
        box_dimension: '',
        length: '',
        images: '',
        description: '',
        fotos: [],
        cantMetros: '',
        categorias:[],
        refresh: false
    }


    async componentDidMount(){
        if(!this.state.refresh)
        window.scrollTo(0, 0)
        if(this.props.match.params.id){
            const res = await axios.get('https://api.todoceramicas.com.uy/products/getProduct.php?&id=' + this.props.match.params.id);

            await this.setState({
                users: res.data,
                _id: this.props.match.params.id,
                refresh: true
                
            })
            window.scrollTo(0, 0)
            const catres = await axios.get('https://api.todoceramicas.com.uy/products/getProducts.php?&category='+4)
            await this.setState({
                categorias: catres.data
            })
            
            await this.state.users.map(note =>(
                this.setState({
                   name: note.name,
                   product_id: note.product_id,
                   model: note.model,
                   price: note.price,
                   width: note.width,
                   box_dimension: note.box_dimension,
                   length: note.length,
                   images: note.images,
                   description: note.description
               })
               
            ))
            await this.setState({
                fotos: JSON.parse(this.state.images)
            })

        }
        window.scrollTo(0, 0)
    }

     onInputChange = async e =>{
        await this.setState({
            [e.target.name]: e.target.value
        })
        
        
    }
    
    render() {
        if(this.props.match.params.id != this.state._id){
            this.componentDidMount()
        }
        const image_array =  this.state.fotos
        const galery_array=[];
        image_array.map(value =>(
            galery_array.push({ original: value, thumbnail: value})
        ))
    let Real = this.state.cantMetros/this.state.box_dimension
    let cajasReal= Math.trunc(this.state.cantMetros/this.state.box_dimension)+1;
    let coma = Real - cajasReal
    if(coma === -1){
        cajasReal = cajasReal -1
    }
    let customItems = this.state.description.split("\n")
    let cantReal= (cajasReal * this.state.box_dimension).toFixed(2);
    if(window.matchMedia("(min-width: 768px)").matches){    

    return(
        <div className='pub_back'>
            <div className="container">
                    <main className="content">
                        <div className='content_border'>
                            <Prueba imagenes={image_array} id={this.props.match.params.id} />
                        </div>
                    </main>
                <div className="sidebar"> 
               
                    <div className='sidebar_title'>
                        {this.state.name}
                    </div>
                    <h3>
                        <div className='flex'>
                        <p className='sidebar_price'>
                            ${this.state.price}
                        </p>
                        <p className='precio_mts'>/m²</p>
                        
                        </div>
                    </h3>
                    <div>
                        <h6>Mts x caja: {this.state.box_dimension} m²</h6> 
                        <h6>Medidas: {this.state.width} x {this.state.length}</h6>
                    </div>
                    <div className='form_cantidad'>
                    <Form>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Cantidad en m²</Form.Label>
                            <Form.Control 
                            type="number" 
                            placeholder="Ingrese cantidad en m²"
                            name="cantMetros"
                            onChange={this.onInputChange}
                            value={this.state.cantMetros}
                            required/>
                        </Form.Group>
                        
                    </Form>
                    <div className='products_button_add'>
                        Cantidad: {cantReal} <br/> Cajas:{cajasReal}
                    </div>
                    <ButtonCookie  idAdd={this.state._id} cantEnM={cantReal} model={this.state.model} name={this.state.name} image={image_array} precio={this.state.price} cajas={cajasReal} />
                    </div>

                </div>
                <div className='public_info'>
                        <div className='carecteristicas1'>
                            <div className='carac_color1'>
                               <p>Modelo</p>
                            </div>
                            <div className='carac_color6'>
                                <p>Ancho</p>
                            </div>
                            <div className='carac_color4'>
                                <p>Largo</p>
                            </div>
                        </div>
                        <div className='carecteristicas2'>  
                            <div className='carac_color2'>
                                <p>{this.state.model}</p>
                            </div>
                            <div className='carac_color3'>
                                <p>{this.state.width} cm</p>
                            </div>
                            <div className='carac_color5'>
                                <p>{this.state.length} cm</p>
                            </div>
                        </div>
                </div>
                {(() => {
                    if (this.state.description !== '') {
                        return (
                            <div className='products_description'>
                                <div className='products_description_title'>
                                    Descripción
                                </div>
                                    <text className='products_description_text'>
                                    {customItems.map(value =>(
                                        <div>
                                            {value}<br/>
                                        </div>
                                    ))}
                                </text>
                            
                            </div>
                        )
                    }
                })()}

                    <div className='pub_rel'>
                        <h3>Publicaciones relacionadas</h3>
                    </div>

                    {this.state.categorias.map((value, index) => {
                            let max = 6
                            if(value.product_id === this.props.match.params.id){
                                max = max + 1
                            }else{
                            if(index < max){
                            let imagenes = JSON.parse(value.images)
                            return(
                                <Link to={'/product/'+value.product_id} className="related-post">
                                    <img src={imagenes[0]} alt=""/>
                                    <div className='text__center'>
                                        <p>{value.name}</p>
                                        <h6>${value.price}</h6>
                                        
                                    </div>
                                </Link>
                            )
                        }}
                        })
                    }
            </div>
        </div>
    );
    }else{
        return(
            <div className='products_mob_container'>
                <div>
                    <Galery images_in={galery_array} />
                </div>
                <div className="products_mob_sidebar">   
                    <div className='products_mob_title'>
                        {this.state.name}
                    </div>
                    <h3>
                        <div className='products_mob_price_cont'>
                        <p className='products_mob_price'>
                            ${this.state.price}
                        </p>
                        <p className='products_mob_price_unit'>/m²</p>
                        
                        </div>
                    </h3>
                    <div>
                        <h6>Mts x caja: {this.state.box_dimension}m²</h6> 
                        <h6>Medidas: {this.state.width} x {this.state.length}</h6>
                    </div>
                    <div>
                    <Form>
                        <Form.Group>
                            <Form.Label className='products_mob_center'>Cantidad en m²</Form.Label>
                            <Form.Control   
                                type="number"
                                placeholder="Ingrese cantidad en m²"
                                name="cantMetros"
                                onChange={this.onInputChange}
                                value={this.state.cantMetros}
                                required/>
                        </Form.Group>
                        cantidad: {cantReal} cajas:{cajasReal}
                    </Form>
                    <ButtonCookie idAdd={this.state._id} cantEnM={cantReal} model={this.state.model} name={this.state.name} image={image_array} precio={this.state.price} cajas={cajasReal} />
                    </div>

                </div>
                <div className='products_mob_title_rel'>
                    Publicaciones Relacionadas
                </div>
                    <CarouselCards tipo_id='2'/> 
            </div>
        )
    }
    }
}
