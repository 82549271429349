import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from 'universal-cookie';

function ButtonEdit({idArray,cantEnM,cajas}) {
  



  const handleSubmit = e => {
    e.preventDefault();
      const cookies = new Cookies();
      var borro = Number(idArray);
      var cant_carry=[];
      var precio_carry=[];
      var cajas_carry=[];
      var subtotal =0;
      subtotal = cookies.get('subtotal');
      cant_carry = cookies.get('cantidad_carry');
      precio_carry= cookies.get('precio');
      cajas_carry= cookies.get('cajas')
      subtotal= subtotal - precio_carry[borro] * cant_carry[borro];
      subtotal= subtotal + precio_carry[borro] * cantEnM;
      cant_carry.splice(idArray, 1, cantEnM);
      cajas_carry.splice(idArray, 1, cajas);
      cookies.set('cantidad_carry', cant_carry, {path: '/'});
      cookies.set('cajas', cajas_carry, {path: '/'});
      cookies.set('subtotal', subtotal, {path: '/'});
      window.location.href='./checkout';
  };



    return (
        <div className="loginContainer">
          <form onSubmit={handleSubmit}>
            <input
              type="submit"
              className="btn btn-primary btnLogin"
              value="Editar presupuesto"
            />
          </form>
        </div>
    );

}

export default ButtonEdit;