import './Login.css';
import React, {Component} from 'react';
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import Cookies from 'universal-cookie';
import md5 from 'md5'
import AdminHome from '../AdminHome'

export default class BannerAdmin extends Component{

    state= {
        usuario: 'admin',
        contrasena: 'fbc71ce36cc20790f2eeed2197898e71',
        user: '',
        password: '',
        alerta: false,
        reinicio: false
    }

    
    onInputChange = e =>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleKeyDown = async (e) => {  
        if(e.key==='Enter'){
            this.onClickButton(e)
        }
    }

    onClickButton = async (e) =>{
        e.preventDefault();
        const clave = md5(this.state.password)
        if(this.state.user === this.state.usuario && this.state.contrasena === clave){
            const cookies = new Cookies();
            cookies.set('adminLogin', true, {path: '/'});
            let autenticate = this.props.inicio;
            if(autenticate === 'autenticate'){
                window.location.href='';
            }else{
                window.location.href='/admin/inicio';
            }
            
        }else{
            this.setState({
                alerta: true,
                password: '',
                reinicio: true
            })
        }
    }

    render(){

        const cookies = new Cookies();
        var adminLogin = cookies.get('adminLogin');
        if(adminLogin === 'true'){
            return(
                <AdminHome location='/inicio'/>
        )}else{
        return(
            <div className='AdminLogin'>
                <div className='AdminLogin_container'>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Usuario</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Usuario"
                                name="user"
                                onChange={this.onInputChange}
                                onKeyDown={this.handleKeyDown}
                                value={this.state.nombre}
                                required 
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control 
                                type="password" 
                                placeholder="Contraseña"
                                name="password"
                                onChange={this.onInputChange}
                                onKeyDown={this.handleKeyDown}
                                value={this.state.nombre}
                                required 
                            />
                        </Form.Group>
                        {(() => {
                            if (this.state.alerta) {
                            return (
                                <Alert variant='danger'>
                                    Usuario o Contraseña invalida
                                </Alert>
                            )
                            }
                        })()}

                        <button type="button" className="btn btn-primary" onClick={this.onClickButton}>
                            Iniciar Sesion
                        </button>
                    </Form>
                </div>
            </div>
        )}
    }
    
}