import Modal from 'react-bootstrap/Modal';
import * as Icon from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import React, {Component} from 'react';
import axios from 'axios'
import './EditImages.css'
import { EmojiObjectsTwoTone } from '@material-ui/icons';


export default class EditImages extends Component{

    state={
        show: false,
        images: [],
        valueimg: '',
        nombre: '',
        precio: '',
        modelo: '',
        ancho: '',
        largo: '',
        boxdim: '',
        oldprice: '',
        description: '',
        sort: '',
        delete: []
    }

    async componentDidMount(){
        const res = await axios.get('https://api.todoceramicas.com.uy/products/getProduct.php?&id=' + this.props.editid);
        const porpd_id= res.data[0]
        this.setState({
            valueimg: '',
            images: JSON.parse(porpd_id.images),
            nombre: porpd_id.name,
            precio: porpd_id.price,
            modelo: porpd_id.model,
            ancho: porpd_id.width,
            largo: porpd_id.length,
            boxdim: porpd_id.box_dimension,
            description: porpd_id.description,
            sort: porpd_id.sort,
            oldprice: porpd_id.oldprice,
        })
    }

  
     handleClose = async () => {

        this.setState({
            show:false,
            valueimg: '',
            delete: []
        })
    }
    
    handleShow = () => {
        this.setState({
            show: true
        })
    };

    OnChangeImage(e){
        if(this.state.valueimg === ''){
            this.setState({
                valueimg: e
            })
        }else{
            let array = this.state.images
            const indice = this.state.valueimg
            const value = this.state.images[indice]
            const nuevo = array[e]
            array.splice(indice,1,nuevo)
            array.splice(e,1,value)
            this.setState({
                valueimg: '',
                images: array
            })
        }
    }

    OnDeleteImage(e){
        let array = this.state.images;
        const value = this.state.images[e]
        let eliminar = this.state.delete;
        array.splice(e,1);
        eliminar.push(value)
        this.setState({
            images: array,
            delete: eliminar
        })
    }

    onSubmit = async (e) => {
        e.preventDefault();
        const jsonimg = await JSON.stringify(this.state.images)
        const newNote ={
            nombre: this.state.nombre,
            precio: this.state.precio,
            oldprice: this.state.oldprice,
            imagenes: jsonimg,
            modelo: this.state.modelo,
            ancho: this.state.ancho,
            largo: this.state.largo,
            boxdim: this.state.boxdim,
            description: this.state.description,
            sort: this.state.sort
        }
        const json = await JSON.stringify(newNote);
        const resjson = await axios.post('https://api.todoceramicas.com.uy/products/editProduct.php?&id='+this.props.editid,json)
        for(let i in this.state.delete){
            const obj={
            url: this.state.delete[i]
            }
            const jsondelete = await JSON.stringify(obj);
            console.log(jsondelete)
            const resjson2 = await axios.post('https://api.todoceramicas.com.uy/images/ceramicas/deleteImage.php',jsondelete)
            console.log(resjson2)
        }        
        this.setState({
            show: false
        })

    }



    render(){
        //console.log(this.state.images)
        //console.log(this.state.delete)
        return (
            <>
                <button  className='btn btn-outline-success CardAdmin_margin' onClick={this.handleShow}><Icon.Camera size={20}/></button>

        
              <Modal className='modal_max' show={this.state.show} onHide={this.handleClose} animation={false}>
                <Modal.Header className='modal_header' closeButton>
                  <Modal.Title className='modal_title' >EDITAR IMAGENES</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='Edit_images_cont'>
                    {this.state.images.map((valux,index) =>(
                        <div>
                          <div className={index===this.state.valueimg ?'Edit_images_img_cont Edit_images_img_shadow'  : 'Edit_images_img_cont'} onClick={() => this.OnChangeImage(index)}>
                              <img className='Edit_images_img' src={valux} alt="" />
                          </div>
                          <div className='Edit_images_delete' onClick={() => this.OnDeleteImage(index)}>
                              X
                          </div>
                        </div>

                      ))
                      }
                    </div>

                </Modal.Body>
                <Modal.Footer className='modal_footer'>
                <Button variant="secondary" onClick={this.onSubmit}>
                    Agregar
                  </Button>
                  <Button variant="secondary" onClick={this.handleClose}>
                    Cerrar
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          );
    }

  }
  
