import './Ceramicas.css'
import React, {Component} from 'react';
import axios from 'axios'
import CardAdmin from '../../containers/cardAdmin';
import NewProduct from './NewProduct'
import './Inicio.css'
import * as Icon from 'react-bootstrap-icons';
import AdminGraph from './AdminGraph'
import AdminGraph2 from './AdminGraph2'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'

export default class AdminInicio extends Component{

    state={
        productos:[],
        presupuestos: [],
        change: false,
        password1: '',
        password2: '',
        alert: false
    }


    async componentDidMount() {
        const res = await axios.get('https://api.todoceramicas.com.uy/products/getProducts.php?&all=1')
        this.setState({productos: res.data})
        const sol = await axios.get('https://api.todoceramicas.com.uy/orders/getOrders.php')
        this.setState({presupuestos: sol.data})
    }

    onClickButton = async (e) =>{
        await this.setState({
            change: !this.state.change
        })
        console.log(this.state.change)
    }

    onInputChange = e =>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onClick = async (e) =>{
        e.preventDefault();
        if(this.state.password2 === this.state.password1){
            await this.setState({
                change: !this.state.change
            })
        }else{
            await this.setState({
                alert: !this.state.alert
            }) 
        }
    }


    render(){


        return(
            <div className='AdminInicio'>
                <div className='AdminInicio_cards_contador_container'>
                    <div className='AdminInicio_cards_contador AdminInicio_cards_color1'>
                        <div className='AdminInicio_cards_text'>
                            <div className='AdminInicio_cards_text_number'>
                                {this.state.productos.length} 
                            </div>
                            <div className='AdminInicio_cards_text_desc'>
                                Productos
                            </div>
                        </div>      
                        <div className='AdminInicio_cards_icon'>
                        <Icon.FileEarmarkBarGraph size={30} color='#68ae00' />
                        </div>
                    </div>
                    <div className='AdminInicio_cards_contador AdminInicio_cards_color2'>
                        <div className='AdminInicio_cards_text'>
                            <div className='AdminInicio_cards_text_number'>
                                {this.state.productos.length} 
                            </div>
                            <div className='AdminInicio_cards_text_desc'>
                                Visitas
                            </div>
                        </div>      
                        <div className='AdminInicio_cards_icon'>
                        <Icon.Eye size={30} color='#fc8213' />
                        </div>
                    </div>
                    <div className='AdminInicio_cards_contador AdminInicio_cards_color3'>
                        <div className='AdminInicio_cards_text'>
                            <div className='AdminInicio_cards_text_number'>
                                {this.state.presupuestos.length} 
                            </div>
                            <div className='AdminInicio_cards_text_desc'>
                                Presupuestos
                            </div>
                        </div>      
                        <div className='AdminInicio_cards_icon'>
                        <Icon.CardChecklist size={30} color='#347ab8' />
                        </div>
                    </div>
                </div>
                <div className='AdminInicio_grafic'>
                    <div className='AdminInicio_grafic_cards'>
                        <div style={{
                            textAlign: 'center',
                            marginBottom: '10px',
                            marginTop: '10px'
                        }}>
                        Evolucion presupuestos
                        </div>
                        <div>
                        <AdminGraph />
                        </div>
                        
                    </div>
                    <div className='AdminInicio_grafic_cards'>
                        <div style={{
                            textAlign: 'center',
                            marginBottom: '10px',
                            marginTop: '10px'
                        }}>
                        Evolucion visitas
                        </div>
                        <div>
                        <AdminGraph2 />
                        </div>
                        
                    </div>
                </div>
               
            </div>
        )
    }

}