import Modal from 'react-bootstrap/Modal';
import * as Icon from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import React, {useState} from 'react';
import Form from 'react-bootstrap/Form'
import './card.css'
import './Modal.css'
import ButtonEdit from './ButtonEdit'

function ModalEdit({idAgregar}) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


  const [cantEnMetros, setCant] = useState();
  var cajas=0;
  if(cantEnMetros){
  cajas= Math.trunc(cantEnMetros/2.3)+1;
  }
  var cantidadReal = (cajas * 2.3).toFixed(1);
    return (
      <>
        <Button variant="secondary" onClick={handleShow}>
            Editar
        </Button>
  
        <Modal className='modal_max' show={show} onHide={handleClose} animation={false}>
          <Modal.Header className='modal_header' closeButton>
            <Modal.Title className='modal_title' >MODIFICAR CANTIDAD:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                <Form> 
                        <Form.Control type="number" value={cantEnMetros} onChange={e => setCant(e.target.value)}  placeholder='Cantidad en m²' />
                </Form>
          </Modal.Body>
          <Modal.Body>
            <div>Cajas : {cajas}</div>
            <div>Cantidad real en metros: {cantidadReal}</div>
          </Modal.Body>
          <Modal.Footer className='modal_footer'>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
            <ButtonEdit idArray={idAgregar} cantEnM={cantidadReal} cajas={cajas} />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
export default ModalEdit;