import './publicidad.css';
import Modal from 'react-bootstrap/Modal';
import * as Icon from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import React, {useState} from 'react';
import pagos from "./pagos-modal.png"


function ModalButtonPagos() {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className='pub__card p1 publicidad_cursor' onClick={handleShow} >
                <div className='pub__cent'>                    
                    <Icon.CreditCard color="#00a1f1" size={38}/>
                    <p className='text'>Metodos de pago</p> 
                </div>
            </div>
    
          <Modal className='modal_envios' show={show} onHide={handleClose} animation={false}>
            <Modal.Header className='modal_header' closeButton>
              <Modal.Title className='modal_title' >PAGOS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='modalpading'>
              <img src={pagos} alt="" width="100%"/>
            </div>
            </Modal.Body>
            <Modal.Footer className='modal_footer'>
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
    
  export default ModalButtonPagos;